import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector } from "recharts";
import { useList, useTable } from "@pankod/refine-core";
import { useMe } from "context/MeContext";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(0)}%)`}
      </text>
    </g>
  );
};

export default function GenderChart() {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_:any, index:any) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const { company } = useMe();
  const { data: dataUserGenderCount } = useList({
    resource: "user-gender-count",
    config: {
      filters: [
        {
          field: "employee_code",
          operator: "eq",
          value: company?.code,
        },
      ],
    },
  });
  const userGenderCount = dataUserGenderCount?.data;
  const usersValue = userGenderCount?.filter((data) => data?.gender === 'Female')[0]?.users || 0;
  const data = [
    { name: "Female", 
      value: userGenderCount?.filter((data) => data?.gender === 'Female')[0]?.users || 0, 
      // fill: "#83a6ed" 
      fill: "#005E83"
    },
    { name: "Male", 
      value: userGenderCount?.filter((data) => data?.gender === 'Male')[0]?.users || 0, 
      // fill: "#8884d8" 
      fill: "#8C2E2E"

    },
    { name: "Undisclosed", 
      value: userGenderCount?.filter((data) => data?.gender === 'Undisclosed')[0]?.users || 0, 
      // fill: "#d0ed57" 
      fill: "#E8B03F"
    },
  ];
  return (
    <PieChart width={400} height={300}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        // cx={200}
        // cy={200}
        innerRadius={75}
        outerRadius={100}
        dataKey="value"
        onMouseEnter={onPieEnter}
        startAngle={180}
        endAngle={0}
      />
    </PieChart>
  );
}
