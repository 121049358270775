import React, { useState, useEffect, useRef } from "react";
import "./forumsPosts.scoped.scss";
import { css } from "@emotion/react";
import { Button, Form } from "antd";
import empty from "assets/img/empty_ava.png";
import { useMe } from "context/MeContext";
import { useCompany } from "context/CompanyContext";
import { Breadcrumb } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMessage, faBell, faReply } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { ForumLayout } from "components/Layout/Forum/forum-layout";
import { useCreate, useList, useOne, useSubscription } from "@pankod/refine-core";
import { useLocation, useParams } from "@pankod/refine-react-router-v6";
import dayjs from "dayjs";
import removeMarkdown from "markdown-to-text";
import toolbarOptions from "../../components/TextEditor/toolbarOptions.js";
import "react-quill/dist/quill.snow.css";
import { IUser } from "types/user";
import ReportButton from "../../components/Report";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/index";
import ReactQuill from "react-quill";

library.add(far, faMessage);
library.add(fas, faBell);
library.add(fas, faReply);

const ForumsPosts: React.FC = () => {
  const [messageReplyId, setMessageReplyId] = useState("");
  const { me: user } = useMe();
  const iconMe = user?.icon || empty;
  const { topicId, postId } = useParams();
  const { hash } = useLocation();
  const { brand, hra_summary } = useCompany();
  const location = useLocation();
  const initialPage = new URLSearchParams(location.search).get("page") || 1;
  const [current, setCurrent] = useState(Number(initialPage));
  React.useEffect(() => {
    setCurrent(Number(initialPage));
  }, [initialPage]);
  const { data: dataUser } = useList<IUser>({
    resource: "users",
  });
  const { data: dataPost } = useOne({
    resource: "post",
    id: postId as string,
    metaData: {
      join: ["user"],
    },
  });
  const post = dataPost?.data;
  const author = post?.user;
  const icon = author?.icon || empty;
  const [form] = Form.useForm();
  const { data: dataMessages, refetch } = useList({
    resource: "message",
    config: {
      pagination: {
        current,
        pageSize: 10,
      },
      filters: [
        {
          field: "post_id",
          operator: "eq",
          value: postId,
        },
      ],
      sort: [
        {
          field: "createdAt",
          order: "asc",
        },
      ],
    },
    metaData: {
      join: ["user"],
    },
  });

  useSubscription({
    channel: "resources/message",
    types: ["*"],
    onLiveEvent: () => {
      refetch();
    },
    params: {
      filters: [
        {
          field: "post_id",
          operator: "eq",
          value: postId,
        },
      ],
    },
  });

  const messages = dataMessages?.data;
  const totalPages = dataMessages && dataMessages.total ? Math.ceil(dataMessages.total / 10) : 0;
  const messageReply = messages?.find((message) => message.id === messageReplyId);
  const messageContentReply = messageReplyId === "replyPost" ? post?.content : messageReply?.content;
  const userSaid = dataUser?.data?.find((user: any) => user.id === messageReply?.user_id);
  const { mutateAsync: createMessage } = useCreate();

  const { data: dataTopic } = useOne({
    resource: "topic",
    id: topicId as string,
  });
  const topic = dataTopic?.data;
  const company_path = user?.company?.path;

  const [content, setContent] = useState("");
  const id = hash?.split("#").pop();

  React.useEffect(() => {
    if (!id) return;
    const ele = document.getElementById(id);
    window.scrollTo({ behavior: "smooth", top: (ele?.offsetTop || 0) - 100 });
  }, [id, messages]);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 100, // Adjust offset as needed
          behavior: "smooth",
        });
      }
    }
  }, [id]);
  const replyBoxRef = useRef<HTMLDivElement | null>(null);
  return (
    <div
      css={css`
        .replyTo {
          border-left: 2px solid ${brand?.color_primary};
        }
        a {
          color: ${brand?.color_primary} !important;
        }
      `}
    >
      <ForumLayout>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "Chat Home",
              href: `/${company_path}/forums`,
            },
            {
              title: `${topic?.name}`,
              href: `/${company_path}/forums/topic/${topic?.id}`,
            },
          ]}
        />

        <div
          className="j-between"
          css={css`
            margin-top: 20px;
          `}
        >
          <div
            className="title"
            css={css`
              color: ${hra_summary?.title_color};
              font-size: 30px;
              font-weight: 600;
              margin: auto 0 auto 5px;
              line-height: normal;
            `}
          >
            {post?.name}
          </div>
        </div>
        <div className="pagination mobile">
          <Pagination current={current} setCurrent={setCurrent} totalPages={totalPages} />
        </div>
        <div className="single-post" style={{ border: `2px solid #D3D3D3` }}>
          <div className="single-post-content j-between">
            <div className="left-column">
              <span>
                <img src={icon} alt={author?.firstname + " " + author?.lastname} className="ava-icon" />
                <p
                  css={css`
                    font-size: 12px;
                    text-align: center;
                  `}
                >
                  {author?.firstname?.substring(0, 15)}
                  {author?.firstname?.length !== undefined && author?.firstname?.length > 15 ? "..." : ""}
                </p>
              </span>
            </div>
            <div className="triangle"></div>
            <div className="last-column">
              <div className="post-date">{dayjs(post?.createdAt).format("MMM DD, YYYY")}</div>
              <div className="line-thin"></div>

              <div className="post-content">
                <ReactQuill value={post?.content} readOnly={true} theme="bubble" />
              </div>
              <div className="bottom-content">
                <div className="line-thin"></div>
                <div className="j-between">
                  <ReportButton
                    post_id={post?.id}
                    user_id={author?.id}
                    content={post?.content}
                    reporter_id={user?.id}
                  />

                  <a
                    className="reply cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setMessageReplyId("replyPost");
                      navigate(`#replybox`);
                      if (replyBoxRef.current) {
                        replyBoxRef.current.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                    href="#replybox"
                  >
                    <FontAwesomeIcon icon={faReply} className="reply-icon" /> Reply
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {messages?.map((message) => {
          const replyBy = message.user;
          const iconM = replyBy?.icon || empty;
          const messageReply = message.replyPost ? post?.content : message?.replyToMessage?.content;
          const replyFrom = dataUser?.data?.find((user: any) => user.id === message?.replyToMessage?.user_id);
          return (
            <div
              className={`single-post ${id === message.id ? "focus" : ""}`}
              id={message.id as string}
              key={message.id}
            >
              <div className="single-post-content j-between">
                <div className="left-column">
                  <span>
                    <img src={iconM} alt={replyBy?.firstname + " " + replyBy?.lastname} className="ava-icon" />
                    <p
                      css={css`
                        font-size: 12px;
                        text-align: center;
                      `}
                    >
                      {replyBy?.firstname?.substring(0, 15)}
                      {replyBy?.firstname?.length > 15 ? "..." : ""}
                    </p>
                  </span>
                </div>
                <div className="triangle"></div>
                <div className="last-column">
                  <div className="post-date">{dayjs(message?.createdAt).format("MMM DD, YYYY")}</div>
                  <div className="line-thin"></div>
                  {messageReply && (
                    <div className="replyTo">
                      <p
                        css={css`
                          color: ${brand?.color_primary};
                          font-weight: 600;
                          background-color: rgba(219, 219, 219, 0.3);
                          padding: 3px;
                        `}
                      >
                        <span style={{ paddingLeft: 10 }}>
                          {replyFrom ? replyFrom?.firstname : author?.firstname} said:
                        </span>
                      </p>
                      <ReactQuill value={messageReply} readOnly={true} theme="bubble" />
                    </div>
                  )}
                  <hr />
                  <div className="post-content">
                    <ReactQuill value={message?.content} readOnly={true} theme="bubble" />
                  </div>
                  <div className="bottom-content">
                    <div className="line-thin"></div>
                    <div className="j-between">
                      <ReportButton
                        message_id={message?.id}
                        user_id={author?.id}
                        content={message?.content}
                        reporter_id={user?.id}
                      />
                      <a
                        className="reply cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setMessageReplyId(message.id as string);
                          navigate(`#replybox`);
                          if (replyBoxRef.current) {
                            replyBoxRef.current.scrollIntoView({ behavior: "smooth" });
                          }
                        }}
                        href="#replybox"
                      >
                        <FontAwesomeIcon icon={faReply} className="reply-icon" /> Reply
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="pagination" style={{ marginTop: 20 }}>
          <Pagination current={current} setCurrent={setCurrent} totalPages={totalPages} />
        </div>
        <div className="single-post reply-post" ref={replyBoxRef} id="replybox">
          <div className="single-post-content j-between">
            <div className="left-column">
              <span>
                <img src={iconMe} alt={user?.firstname + " " + user?.lastname} className="ava-icon" />
                <p
                  css={css`
                    font-size: 12px;
                    text-align: center;
                  `}
                >
                  {user?.firstname?.substring(0, 15)}
                  {user?.firstname?.length !== undefined && user?.firstname?.length > 15 ? "..." : ""}
                </p>
              </span>
            </div>
            <div className="triangle"></div>
            <div className="last-column reply-group">
              {messageContentReply && (
                <div className="replyTo" style={{ padding: "0 !important" }}>
                  <p
                    css={css`
                      color: ${brand?.color_primary};
                      font-weight: 600;
                      background-color: rgba(219, 219, 219, 0.3);
                      padding: 3px;
                    `}
                  >
                    <span style={{ paddingLeft: 10 }}>{userSaid ? userSaid?.firstname : author?.firstname} said:</span>
                  </p>
                  <ReactQuill value={messageContentReply} readOnly={true} theme="bubble" />
                </div>
              )}
              <Form
                className="reply-form"
                form={form}
                onFinish={async (e) => {
                  const res = await createMessage({
                    resource: "message",
                    values: {
                      ...e,
                      post: {
                        id: postId,
                      },
                      user: {
                        id: user?.id,
                      },
                      content_raw: removeMarkdown(e.content),
                      replyPost: messageReplyId === "replyPost",
                      reply_to_message_id:
                        messageReplyId && messageReplyId !== "replyPost" ? messageReplyId : undefined,
                    },
                    successNotification: false,
                  });
                  if (res.data.id) {
                    refetch();
                    setMessageReplyId("");
                    form.resetFields();
                  }
                }}
              >
                <Form.Item name="content">
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    //modules={modules}
                    modules={{ toolbar: toolbarOptions }}
                  />
                </Form.Item>
                <div className="reply-button-group">
                  <Button
                    className="reply-button"
                    onClick={() => form.submit()}
                    css={css`
                      background-color: ${brand?.color_primary};
                      &:hover {
                        border-color: ${hra_summary?.title_color} !important;
                        background-color: ${hra_summary?.title_color} !important;
                      }
                    `}
                  >
                    <FontAwesomeIcon icon={faReply} style={{ marginRight: 5 }} />
                    Post reply
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </ForumLayout>
    </div>
  );
};

export default ForumsPosts;
