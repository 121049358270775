import React, { useState } from "react";
import "./forumsTopics.scoped.scss";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { Button } from "antd";
import { Breadcrumb } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMessage, faUser, faBell, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "@pankod/refine-react-router-v6";
import { useList, useOne } from "@pankod/refine-core";
import dayjs from "dayjs";
import { ForumLayout } from "components/Layout/Forum/forum-layout";
library.add(far, faMessage);
library.add(fas, faBell);
library.add(far, faPenToSquare);

const ForumsTopics: React.FC = () => {
  const { path, brand, hra_summary } = useCompany();
  const { topicId } = useParams();
  const [current, setCurrent] = useState(1);
  const { data } = useList({
    resource: "post",
    config: {
      pagination: {
        current,
        pageSize: 10,
      },
      filters: [
        {
          field: "topic_id",
          operator: "eq",
          value: topicId,
        },
      ],
      sort: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    metaData: {
      join: [
        {
          field: "user",
        },
        {
          field: "topic",
        },
        {
          field: "topic.category",
        },
      ],
    },
  });

  const posts = data?.data ?? [];

  const totalPages = data && data.total ? Math.ceil(data.total / 10) : 0;

  const { data: dataTopic } = useOne({
    resource: "topic",
    id: topicId as string,
  });
  const topic = dataTopic?.data;

  const url = `/${path}/forums/topic/${topicId}/new-post`;
  const { postId } = useParams();
  const { data: dataMessages } = useList({
    resource: "message",
    config: {
      filters: [
        {
          field: "post_id",
          operator: "eq",
          value: postId,
        },
      ],
      sort: [
        {
          field: "createdAt",
          order: "asc",
        },
      ],
    },
    metaData: {
      join: ["user"],
    },
  });
  const messages = dataMessages?.data;
  return (
    <ForumLayout>
      <Breadcrumb
        separator=">"
        items={[
          {
            title: "Chat Home >",
            href: `/${path}/forums`,
          },
        ]}
      />

      <Link to={url} style={{ float: "right", height: "100%", display: "block" }}>
        <Button className="post-button">
          <FontAwesomeIcon icon={faPenToSquare} className="pen-icon" />
          New Post
        </Button>
      </Link>

      <div
        className="j-between"
        css={css`
          margin-top: 60px;
          h2 {
            width: 70% !important;
            color: ${hra_summary?.title_color};
            font-size: 30px;
            font-weight: 600;
            margin: auto 0 auto 5px;
            line-height: normal;
          }
          .prev-group {
            display: flex;
            button:focus {
              outline: revert;
            }
            button:disabled {
              color: #d8d8d8 !important;
              cursor: not-allowed;
            }
            .prev {
              width: 40px;
              height: 40px;
              text-align: center;
              transition: background-color 0.3s;
              border-radius: 5px;
              color: ${brand?.color_primary};
              margin-left: 10px;
            }
            .number {
              pointer-events: none;
              background-color: ${brand?.color_primary};
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .prev-button {
              font-weight: 700;
              cursor: pointer;
              border: 2px solid transparent;
              background-color: transparent;
              &:hover {
                border: 2px solid ${brand?.color_primary};
              }
            }
          }
        `}
      >
        <h2>{topic?.name}</h2>
        <div className="pagination-controls prev-group">
          <button
            className="prev prev-button previous"
            onClick={() => setCurrent((prev) => prev - 1)}
            disabled={current === 1}
          >
            {"<"}
          </button>
          <span className="prev number">{current}</span>
          <button
            className="prev prev-button next"
            onClick={() => setCurrent((prev) => prev + 1)}
            disabled={current === totalPages}
          >
            {">"}
          </button>
        </div>
      </div>

      <div className="table-wrapper">
        <table className="forums-table">
          <tbody>
            {posts?.map((post) => {
              const url = `/${path}/forums/topic/${topicId}/post/${post.id}`;
              const replyAmount = messages?.filter((item) => item?.post_id === post?.id).length;

              return (
                <tr key={post.id}>
                  <td className="table-cell left-column">
                    <span>
                      <Link to={url} className="underline-hover">
                        {post?.name}
                      </Link>
                    </span>
                  </td>
                  <td className="table-cell align-center mid-column">
                    <div>Replies</div>
                    <div className="threads-number">{replyAmount}</div>
                  </td>
                  <td className="table-cell last-column">
                    {/* {content} ... */}
                    <div className="last-column-column">
                      {dayjs(post?.createdAt).format("MMM DD, YYYY")}
                      <div>
                        <FontAwesomeIcon icon={faUser} className="user-icon" /> {post?.user?.firstname}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </ForumLayout>
  );
};

export default ForumsTopics;
