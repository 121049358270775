import { Form, Input } from "@pankod/refine-antd";
import { ListStats } from "./ListStats";

export const DiffListStats: React.FC = () => {
  return (
    <Form.List name={"diff_stats"}>
      {(fields) => (
        <div>
          {fields.map((field) => {
            return (
              <div key={field.key} className="relative border-solid border-2 border-gray-200 p-3 rounded mt-3">
                <Form.Item name={[field.name, "week"]} hidden>
                  <Input />
                </Form.Item>
                <span className="font-bold">Week {field.name + 1}</span>
                <ListStats field={field} />
              </div>
            );
          })}
        </div>
      )}
    </Form.List>
  );
};
