import React, {useRef} from 'react';
import { css } from '@emotion/react';
import { Table, Button } from 'antd';
import type { TableColumnsType } from 'antd';
import { createStyles } from 'antd-style';
import { useList} from '@pankod/refine-core';
import { useMe } from "context/MeContext";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../adminDashboard.scoped.scss"
import * as XLSX from 'xlsx';
import { ExportOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
library.add(faDownload);



interface DataType {
  key: React.Key;
  firstname: string;
  lastname: string;
  email: string;
  point: number;
}

const useStyle = createStyles(({ css }) => {
    return {
      customTable: css`
        .ant-table {
          .ant-table-container {
            .ant-table-body,
            .ant-table-content {
              scrollbar-width: thin;
              scrollbar-color: unset;
            }
          }
        }
      `,
    };
  });

const data: TableColumnsType<DataType> = [
  // {
    // title: "ALL PARTICIPANTS",
    // children: [
      {
        title: 'No.',
        dataIndex: 'key',
        width: 70,
      },
      {
        title: 'First Name',
        dataIndex: 'firstname',
        width: 200,
      },
      {
        title: 'Last Name',
        dataIndex: 'lastname',
        width: 200,
      },
    
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Point',
        dataIndex: 'point',
        width: 150,
      },
    // ]
  // }
];

const dataSource = Array.from({ length: 100 }).map<DataType>((_, i) => ({
  key: i,
  firstname: `first name ${i}`,
  lastname: `last name ${i}`,
  email: `firstname+lastname${i}@gmail.com`,
  point: Math.floor(Math.random() * (100 - 5 + 1)) + 5,
}));


const AllPartipipants: React.FC = () => {
  const { styles } = useStyle();
  const { company } = useMe();
  const tableRef = useRef(null);
  
  const ExportPDF = () => {
    if (!dataSource) return;
    const doc = new jsPDF();
    doc.text("All Participants", 14, 10);
    const tableColumn = ["No.","First Name", "Last Name", "Email", "Point"]
    const tableRows: any[] = [];
    dataSource?.forEach((item: any) => {
      const rowData = [item.key, item.firstname, item.lastname, item.email, item.point];
      tableRows.push(rowData);
    });
    (doc as any).autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });
    doc.save("participant_list.pdf");
  }

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataSource);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate buffer and save the file
    XLSX.writeFile(workbook, 'all-participants.xlsx');
  };
  
  const title = () => (
    <div className="table-header">
      <span className="table-title">ALL PARTICIPANTS</span>
      <Button className="export-button" onClick={ExportPDF} style={{marginRight:10}}>
        <FontAwesomeIcon icon={faDownload} className="icon" /> PDF
      </Button>
      <Button className="export-button" onClick={exportToExcel}>
        <FontAwesomeIcon icon={faDownload} className="icon" /> Excel
      </Button>
    </div>
  );
  return (
    <div css={css`
      .icon {
      margin-right: 10px;
      }
    `}>
      <Table<DataType>
        className={styles.customTable}
        columns={data}
        dataSource={dataSource}
        //pagination={{ pageSize: 10 }}
        scroll={{ y: 55 * 5 }}
        title={title}
      >
      </Table>
    </div>
  );
};

export default AllPartipipants;
