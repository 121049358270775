import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector } from "recharts";
import { useList } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import { useCompany } from "context/CompanyContext";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(0)}%)`}
      </text>
    </g>
  );
};

export default function AgeChart() {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_:any, index:any) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const { company } = useMe();
  const { data: dataUserAgeCount } = useList({
    resource: "user-age-count",
    config: {
      filters: [
        {
          field: "employee_code",
          operator: "eq",
          value: company?.code,
        },
      ],
    },
  });
  const userAgeCount = dataUserAgeCount?.data;
  //console.log({userAgeCount})
  //const usersValue = userAgeCount?.filter((data) => data?.age_group === '18-25')[0]?.users || 0;
  const data = [
    {
      name: "Age 18-25",
      value: userAgeCount?.filter((data) => data?.age_group === '18-25')[0]?.users || 0,
      fill: "#E8B03F"
    },
    {
      name: "Age 26-35",
      value: userAgeCount?.filter((data) => data?.age_group === '26-35')[0]?.users || 0,
      fill: "#005E83",
    },
    {
      name: "Age 36-45",
      value: userAgeCount?.filter((data) => data?.age_group === '36-45')[0]?.users || 0,
      fill: "#008393",
    },
    {
      name: "Age 46-55",
      value: userAgeCount?.filter((data) => data?.age_group === '46-55')[0]?.users || 0,
      // fill: "#d55031",
      fill: "#8C2E2E",
    },
    {
      name: "Age 56-65",
      value: userAgeCount?.filter((data) => data?.age_group === '56-65')[0]?.users || 2,
      fill: "#d55031"
    },
    {
      name: "Age 65+",
      value: userAgeCount?.filter((data) => data?.age_group === '>65')[0]?.users || 0,
      fill: "#d7842c",
    },
  ];

  return (
    <PieChart width={450} height={300}>
    <Pie
      activeIndex={activeIndex}
      activeShape={renderActiveShape}
      data={data}
      // cx={200}
      // cy={200}
      innerRadius={75}
      outerRadius={100}
      //fill="#83a6ed"
      dataKey="value"
      onMouseEnter={onPieEnter}
      startAngle={90}
      endAngle={450}
    />
  </PieChart>
  );
}
