import {
  useForm,
  Form,
  Input,
  Row,
  Col,
  Create,
  InputNumber,
  DatePicker,
  Select, 
  useSelect,
  Switch,
  Button,
} from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { CardAdmin } from "components/Card/CardAdmin";
import React, { useState } from "react";
import { UploadImage } from "components/Upload/Upload";
import { DataType } from "types/challenge";
import "./challengeAdmin.scoped.scss";
import { DiffListStats } from "./components/DiffListStats";
import { ListStats } from "./components/ListStats";
interface FormValues {
  company_ids: string[];
}
export const dataTypeOps = [
  {
    value: DataType.Numeric,
    label: DataType.Numeric,
  },
  {
    value: DataType["Yes/No"],
    label: DataType["Yes/No"],
  },
  {
    value: DataType.Dropdown,
    label: DataType.Dropdown,
  },
  {
    value: DataType.Date,
    label: DataType.Date,
  },

  {
    value: DataType.FreeText,
    label: DataType.FreeText,
  },
];

export const ChallengeCreate: React.FC = () => {
  const { push } = useNavigation();
  const [value, setValue] = useState("SameWeek");
  const { formProps, saveButtonProps, form, formLoading } = useForm({
    resource: "challenge",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/challenge");
    },
  });
  const [loading, setLoading] = useState(false);
  const { selectProps } = useSelect({
    resource: "company",
    optionLabel: "name",
    optionValue: "id",
  });
  const handleChange = (value: any) => {
    setValue(value);
  };

  // const navigate = useNavigate();
  const handlePreview = async () => {
    try {
      const values = (await form.validateFields()) as FormValues;
      const companies = values.company_ids.map((id: string) => ({
        id,
      }));

      console.log("companiesss", companies);
      // Set preview flag
      formProps.onFinish?.({ ...values, companies, preview: true });
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };
  const weeks = Form.useWatch("duration", form);

  const _diff_stats = Array.from(Array(weeks).keys()).map((a) => {
    return {
      week: a,
      stats: [
        {
          title: "",
          description: "",
          data_type: "",
        },
      ],
    };
  });
  React.useEffect(() => {
    form.setFieldValue("diff_stats", _diff_stats);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeks]);

  return (
    <Create
      saveButtonProps={{ ...saveButtonProps, children: "Publish" }}
      footerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <Button type="default" onClick={handlePreview}>
            Preview
          </Button>
        </>
      )}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(e: any) => {
          const companies = e.company_ids.map((id: string) => {
            return {
              id,
            };
          });
          formProps.onFinish?.({ ...e, companies, preview: false });
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={8}>
            <CardAdmin title="General information">
              <Form.Item
                label={"Company"}
                name={"company_ids"}
                rules={[
                  {
                    required: true,
                  },
                ]}
                className="w-full"
              >
                <Select {...selectProps} mode="multiple" placeholder="Company" />
              </Form.Item>
              <Form.Item
                label={"Challenge name"}
                name="name"
                className="w-[300px]"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Challenge name" />
              </Form.Item>
              <Form.Item
                className="w-1/2"
                label={"Start date"}
                name="start_date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                className="w-1/2"
                label={"Duration (weeks)"}
                name="duration"
                rules={[
                  {
                    required: true,
                    type: "number",
                  },
                ]}
              >
                <InputNumber min={0} type="number" />
              </Form.Item>

              <Form.Item
                className="w-1/2"
                label={"Grace period"}
                name="grace_period"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item className="w-1/2" label={"Allow teams"} name="allow_team" valuePropName="checked">
                <Switch />
              </Form.Item>
              {/* <Form.Item className="w-1/2" label={"Status"} name="status" valuePropName="checked">
                <Switch />
              </Form.Item> */}
              {/* <Form.Item className="w-1/2" label={"Preview"} name="preview" valuePropName="checked">
                <Switch />
              </Form.Item> */}
              <Form.Item
                label={"Description"}
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label={"Header Image"}
                name="header_img"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <UploadImage
                  setLoading={setLoading}
                  loading={loading || formLoading}
                  form={form}
                  isDefault={false}
                  name="header_img"
                />
              </Form.Item>
              <Form.Item
                label={"Scoring Info Image"}
                name="scoring_img"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <UploadImage
                  setLoading={setLoading}
                  loading={loading || formLoading}
                  form={form}
                  isDefault={false}
                  name="scoring_img"
                />
              </Form.Item>
              <Form.Item
                label={"Helpful Hint Image"}
                name="helpful_img"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <UploadImage
                  setLoading={setLoading}
                  loading={loading || formLoading}
                  form={form}
                  isDefault={false}
                  name="helpful_img"
                />
              </Form.Item>
            </CardAdmin>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name={"mode"}>
              <Select
                defaultValue="SameWeek"
                style={{ width: 300 }}
                options={[
                  { value: "SameWeek", label: "Same challenges for each week" },
                  { value: "DiffWeek", label: "Different challenges for each week" },
                ]}
                onChange={handleChange}
                value={value}
              />
            </Form.Item>
            <div className={`${value === "SameWeek" ? "" : "disabled"}`}>
              <CardAdmin title="Same challenges for each week">
                <ListStats />
              </CardAdmin>
            </div>
            <div className={`${value === "DiffWeek" ? "" : "disabled"}`}>
              <CardAdmin title="Different challenges for each week">
                <DiffListStats />
              </CardAdmin>
            </div>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
