import { RcFile, Spin, Upload, UploadFile, UploadProps } from "@pankod/refine-antd";
import { Button, message } from "antd";
import React from "react";
import { useLocalStorage } from "hooks/useAuth/useLocalStorage";
import { config } from "config";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadChangeParam } from "antd/es/upload";

const beforeUpload = (file: RcFile) => {
  const isLt10M = file.size / 1024 / 1024 <= 10;
  if (!isLt10M) {
    message.error("Image must smaller than or equal 10MB!");
  }
  return isLt10M;
};

export interface UploadFileProps {
  setLoading: (value: boolean) => void;
  loading: boolean;
  files: string[];
  callback: (value: string) => void;
  onRemoveFile: (value: string) => void;
  isDisabled?: boolean;
  btnName?: string;
  normal?: boolean;
  maxCount?: number;
}

export const UploadFiles: React.FC<UploadFileProps> = ({
  setLoading,
  loading,
  files,
  callback,
  isDisabled,
  onRemoveFile,
  btnName = "Upload Proof Items",
  normal = true,
  maxCount = 3,
}) => {
  const [token] = useLocalStorage<any>(config.TOKEN_KEY, {});

  const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      callback?.(info.file.response);
      setLoading(false);
      info.file.url = config.ASSET_URL + info.file.response;
    }
  };

  const defaultFiles = files.map((file, index) => {
    let indexString = (index + 1).toString();
    return {
      uid: file,
      url: config.ASSET_URL + file,
      name: normal ? "proof item " + indexString : file,
    };
  });

  return (
    <Spin spinning={loading}>
      <Upload
        name="file"
        accept="image/*,.pdf"
        action={`${config.API_URL}/media/upload`}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        headers={{
          Authorization: `Bearer ${token?.accessToken}`,
        }}
        multiple={true}
        listType="text"
        maxCount={maxCount}
        disabled={isDisabled}
        onRemove={(file) => {
          onRemoveFile(file.uid);
        }}
        defaultFileList={defaultFiles}
      >
        <Button icon={<UploadOutlined />} disabled={isDisabled}>
          {btnName}
        </Button>
      </Upload>
    </Spin>
  );
};
