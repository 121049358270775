import React, { useRef } from "react";
import "./contact.scoped.scss";
import emailjs from "@emailjs/browser";
import { css } from "@emotion/react";
import { IconPhoneCall, IconEnvelope, IconLocationOutline } from "../../components/Icons/index";
import { message } from "antd";
import { useCompany } from "context/CompanyContext";
import FAQ from "./faq";
import routerProvider from "@pankod/refine-react-router-v6";
const { Link } = routerProvider;

const Contact: React.FC = () => {
  const REACT_APP_EMAIL_KEY = process.env.REACT_APP_EMAIL_KEY;
  // cách 1
  const company = useCompany();
  const { contact, brand, hra_summary, path: company_path } = company;
  // cách 2
  // const { contact } = useCompany();
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    emailjs.sendForm("service_ddnvx7q", "template_j6kpq1o", e.target, REACT_APP_EMAIL_KEY).then(
      (result) => {
        console.log("result", result.text);
      },
      (error) => {
        console.log("error", error.text);
      },
    );
  };
  const openMessage = (e: any) => {
    messageApi.open({
      key,
      type: "loading",
      content: "Loading...",
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: "success",
        content: "Message sent! Thank you for contacting us, we will get back to you as soon as possible.",
        duration: 5,
      });
    }, 1000);
  };

  return (
    <div className="contact-page pageload">
      {contextHolder}
      <div
        css={css`
          width: 100%;
          text-align: center;
        `}
      >
        <h1
          css={css`
            border-bottom: 1px solid ${brand?.color_primary};
            padding-bottom: 5px;
            width: 50%;
            display: inline-block;
            color: ${brand?.color_primary};
            font-weight: 400 !important;
          `}
        >
          Contact Us
        </h1>
      </div>
      <div
        className="contact-page-container"
        css={css`
          .buton {
            background-color: ${brand?.color_primary};
          }
          p {
            color: #6d7381;
          }
          @media screen and (max-width: 768px) {
            .contact-column-left {
              background-color: ${brand?.color_primary};
            }
          }
        `}
      >
        <div className="container j-between" style={{alignItems: "center", padding: "0 20px"}}>
        <div className="contact-column-right contact-column">
          <div className="main-title-title info black-text" style={{marginBottom: 20}}>Contact Us</div>
          <div className="">
            <div className="align-left">
              <h4>{contact?.title}</h4>
              {company_path !== "marshall" ? "" : <p className="contact-people">Jeff Hunter</p>}
              <a href={`tel:${contact?.phone}`}>
                <p>
                  <IconPhoneCall />
                  <span>{contact?.phone}</span>
                </p>
              </a>
              <a href={`mailto:${contact?.email}`}>
                <p>
                  <IconEnvelope />
                  <span>{contact?.email}</span>
                </p>
              </a>
              <a href={`${contact?.website}`} target="_blank" rel="noreferrer">
                <p>
                  <IconLocationOutline />
                  <span>{contact?.address}</span>
                </p>
              </a>
              {company_path !== "marshall" ? (
                ""
              ) : (
                <div>
                  <br />
                  <p className="contact-people">Chief Wellness Officer</p>
                  <p className="contact-people">Dr. Leanne Camisa</p>
                  <a href={`mailto:${contact?.email}`}>
                    <p>
                      <IconEnvelope />
                      <span>lcamisa@marshallmedical.org</span>
                    </p>
                  </a>
                  <br />
                  <p className="contact-people">Marcella Fox</p>
                  <a href={`mailto:${contact?.email}`}>
                    <p>
                      <IconEnvelope />
                      <span>mfox@marshallmedical.org</span>
                    </p>
                  </a>
                  <a href={`tel:${contact?.phone}`}>
                    <p>
                      <IconPhoneCall />
                      <span>(530) 626-2811</span>
                    </p>
                  </a>
                </div>
              )}
            </div>
            <br />
            <div className="hr"></div>
            <div className="align-right">
              {/* {company_path === "marshall" ? <h4>Monument Health - Questions about the wellness platform or technical assistance with the platform</h4> : <h4>Monument Health - Questions about medical benefits, finding a provider, in-network options</h4>} */}
              <h4>{contact?.mh_title}</h4>
              <a href="tel:9706835690">
                <p>
                  <IconPhoneCall />
                  <span>(970) 683-5630</span>
                </p>
              </a>
              <a href="mailto:support@monumenthealth.net">
                <p>
                  <IconEnvelope />
                  <span>goodlife.support@monumenthealth.net</span>
                </p>
              </a>
              <a href="https://monumenthealth.net/" target="_blank" rel="noreferrer">
                <p>
                  <IconLocationOutline />
                  <span>
                  461 Main Street, Suite 200, Grand Junction, CO 81501
                  </span>
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="contact-column-left cotact-column" onSubmit={sendEmail} id="contact-form">
          <form
            ref={form}
            onSubmit={sendEmail}
            id="contact-form"
            css={css`
              color: ${brand?.color_primary} !important;
              border-color: ${brand?.color_primary} !important;
              font-size: 20px !important;
            `}
          >
            <h4 className="need-help grey-text">Need help ?</h4>
            <div className="main-title-title contact black-text">Send us a message</div>
            <div className="contact-responsive">
              <div className="align-left">
                <h4 className="black-text">{contact?.title}</h4>
                <a href={`tel:${contact?.phone}`}>
                  <p>
                    <IconPhoneCall />
                    <span>{contact?.phone}</span>
                  </p>
                </a>
                <a href={`mailto:${contact?.email}`}>
                  <p>
                    <IconEnvelope />
                    <span>{contact?.email}</span>
                  </p>
                </a>
                <a href={`${contact?.website}`} target="_blank" rel="noreferrer">
                  <p>
                    <IconLocationOutline />
                    <span>{contact?.address}</span>
                  </p>
                </a>
                {company_path !== "marshall" ? (
                  ""
                ) : (
                  <div>
                    <br />
                    <p className="contact-people">Chief Wellness Officer</p>
                    <p className="contact-people">Dr. Leanne Camisa</p>
                    <a href={`mailto:${contact?.email}`}>
                      <p>
                        <IconEnvelope />
                        <span>lcamisa@marshallmedical.org</span>
                      </p>
                    </a>
                    <br />
                    <p className="contact-people">Dr. Leanne Camisa</p>
                    <a href={`mailto:${contact?.email}`}>
                      <p>
                        <IconEnvelope />
                        <span>mfox@marshallmedical.org</span>
                      </p>
                    </a>
                    <a href={`tel:${contact?.phone}`}>
                      <p>
                        <IconPhoneCall />
                        <span>(530) 626-2811</span>
                      </p>
                    </a>
                  </div>
                )}
              </div>
              <br />
              <div className="align-right">
                {company_path === "marshall" ? (
                  <h4 className="black-text">
                    Monument Health - Questions about the wellness platform or technical assistance with the platform
                  </h4>
                ) : (
                  <h4 className="black-text">Monument Health - Questions about medical benefits, finding a provider, in-network options</h4>
                )}
                <a href="tel:9706835690">
                  <p>
                    <IconPhoneCall />
                    <span>(970) 683-5630</span>
                  </p>
                </a>
                <a href="mailto:support@monumenthealth.net">
                  <p>
                    <IconEnvelope />
                    <span>goodlife.support@monumenthealth.net</span>
                  </p>
                </a>
                <a href="https://monumenthealth.net/" target="_blank" rel="noreferrer">
                  <p>
                    <IconLocationOutline />
                    <span>
                      461 Main Street, Suite 200<div>Grand Junction, CO 81501</div>
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <input type="text" name="name" placeholder="Name" className="form-input" />
            <input type="email" name="email" placeholder="Email" className="form-input" />
            <textarea name="message" placeholder="Message" className="form-input textarea" />
            <input
              type="submit"
              value="Send"
              className="button"
              onClick={openMessage}
              css={css`
                margin-bottom: 10px !important;
                border-color: ${brand?.color_primary} !important;
                background-color: ${brand?.color_primary} !important;
                &:hover {
                  transition: 0.5s ease-in-out !important;
                  background-color: ${hra_summary?.title_color};
                }
              `}
            />
            <div css={css`
              font-size:14px !important;
              font-weight: 300 !important;
              color: #BCBCBC;
              text-align: center;
              line-height: normal;
              .bold {
                color: #6c6c6c;
              }
            `}>By contacting us, you aree to our 
              <Link to={`/terms`} className="bold"> Terms of service </Link>
                and 
              <Link to={`/privacy`} className="bold"> Privacy Policy</Link>
            </div>
          </form>
        </div>
        </div>
      </div>
      <div className="map-group mobile-none j-between container">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12388.6561845785!2d-108.58513747661385!3d39.079960946967816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87471d9e7ac81375%3A0xf65fa707c01a615c!2sMonument%20Health!5e0!3m2!1sen!2sus!4v1730399843787!5m2!1sen!2sus" width="600" height="400" loading="lazy"></iframe>
        <div className="location-text">
          <h4 className="our-location grey-text">Our Location</h4>
          <div className="connecting-near-far black-text">Connecting Near and Far</div>
          <h4 className="head-office black-text">Monument Health</h4>
          <div className="address grey-text">
            461 Main Street, Suite 200 <br/>
            Grand Junction, CO 81501<br/>
            United States
          </div>
        </div>
      </div>
      <div className="faq-group j-between container mobile-none">
        <div className="faq-left">
          <h4 className="our-location grey-text">FAQ</h4>
          <div className="connecting-near-far black-text" style={{lineHeight: "normal", marginTop: 20}}>Do you have any questions for us?</div>
          <div className="address grey-text" style={{margin: "15px 0", lineHeight:"20px"}}>
            Please submit your email below and our support team will get back to you
          </div>
          <form ref={form} onSubmit={sendEmail} className="j-between">
            <input type="email" name="email" placeholder="Enter your email" className="form-input" css={css`
                height: 100% !important;
                margin-top: 0 !important;
                margin-right: 10px !important;
              `}/>
            <input
              type="submit"
              value="Submit"
              className="button"
              onClick={openMessage}
              css={css`
                letter-spacing: 0 !important;
                font-weight: 400;
                margin-top: 0 !important;
                height: 100% !important;
                font-size: 13px !important;
                padding: 4px 0px 4px 0px !important;
                width: 40% !important;
                border-color: ${brand?.color_primary} !important;
                background-color: ${brand?.color_primary} !important;
                &:hover {
                  transition: 0.5s ease-in-out !important;
                  background-color: ${hra_summary?.title_color};
                }
              `}
            />
          </form>
        </div>
        <div className="faq-right">
          <FAQ />
        </div>
      </div>
    </div>
  );
};

export default Contact;
