import { UploadFiles } from "components/Upload/UploadFile";
import { Button } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";

interface UploadProofProps {
  disabled: boolean;
  loading: boolean;
  setLoading: (value: boolean) => void;
  callback: (value: string) => void;
  onRemoveFile: (value: string) => void;
  files: string[];
  data?: any;
}

export const UploadProof: React.FC<UploadProofProps> = ({
  disabled,
  files,
  loading,
  setLoading,
  callback,
  onRemoveFile,
  data,
}) => {
  // if (data?.hidden_required_proof) return null;
  if (data?.hidden_required_proof) return (
    <div className="right-right-col col upload-col" css={css`
      margin: auto 3px;
    `}>
      <Button disabled icon={<UploadOutlined />} >
        No Proof Required
      </Button>
      </div>
  );
  return (
    <div className="right-right-col col upload-col three">
      <UploadFiles
        key={files[files.length - 1]}
        isDisabled={disabled}
        loading={loading}
        setLoading={setLoading}
        callback={callback}
        onRemoveFile={onRemoveFile}
        files={files}
      />
    </div>
  );
};
