import { useEffect, useState } from "react";
import React from "react";
import { LoadingFullPage } from "components/Loading/FullPage";
import { config } from "config";
import router from "@pankod/refine-react-router-v6";
import { getToken } from "auth/azure";

const LoginRedirect = () => {
  const [text, setText] = useState("Loading...");
  const [azToken, setAzToken] = useState("");
  const pathname = window.location.pathname;
  const provider = pathname.split("/")[2];
  const location = router.useLocation();
  const id_token_hash = location.hash;
  React.useEffect(() => {
    getToken().then((token) => {
      setAzToken(token);
    });
  }, [id_token_hash]);

  useEffect(() => {
    // Successfully logged with the provider
    if (provider === "microsoft") {
      azToken &&
        fetch(
          `${config.API_URL}/auth/${provider}/redirect${location.search}`,
          azToken
            ? {
                headers: {
                  Authorization: `Bearer ${azToken}`,
                },
              }
            : undefined,
        )
          .then((res) => {
            if (res.status !== 200) {
              throw new Error(`Couldn't login to Service. Status: ${res.status}`);
            }
            return res;
          })
          .then((res) => res.json())
          .then((res) => {
            // Now saving the jwt to use it for future authenticated requests to Nestjs
            localStorage.setItem(config.TOKEN_KEY, JSON.stringify(res.tokens));
            const user = res.user && JSON.stringify(res.user);
            localStorage.setItem("user-health", user);

            window.location.href = "/";
          })
          .catch((err) => {
            setText("An error occurred, please see the developer console.");
          });
    } else {
      fetch(`${config.API_URL}/auth/${provider}/redirect${location.search}`)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error(`Couldn't login to Service. Status: ${res.status}`);
          }
          return res;
        })
        .then((res) => res.json())
        .then((res) => {
          // Now saving the jwt to use it for future authenticated requests to Nestjs
          localStorage.setItem(config.TOKEN_KEY, JSON.stringify(res.tokens));
          const user = res.user && JSON.stringify(res.user);
          localStorage.setItem("user-health", user);

          window.location.href = "/";
        })
        .catch((err) => {
          setText("An error occurred, please see the developer console.");
        });
    }

    return () => {};
  }, [azToken, location.search, provider]);

  return <LoadingFullPage text={text} />;
};

export default LoginRedirect;
