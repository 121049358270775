import { useForm, Form, Row, Col, InputNumber, Edit } from "@pankod/refine-antd";
import { useCreate, useList, useUpdate } from "@pankod/refine-core";
import { CardAdmin } from "components/Card/CardAdmin";
import React from "react";

export const HRAAdmin: React.FC = () => {
  const { data: dataRawSettings } = useList({
    resource: "settings",
  });
  const setting = dataRawSettings?.data[0];
  const { formProps, saveButtonProps } = useForm({
    resource: "settings",
    id: setting?.id,
    redirect: false,
    queryOptions: {
      enabled: !!setting?.id,
    },
  });
  const { mutateAsync: create } = useCreate();
  const { mutateAsync: update } = useUpdate();
  return (
    <Edit saveButtonProps={saveButtonProps} title={<h1 style={{fontSize: 35, marginBottom:30, color:"rgb(4, 6, 10, 0.8)"}}>Graph Settings</h1>} >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(e) => {
          if (setting?.id) {
            update({
              resource: "settings",
              values: e,
              id: setting.id,
            });
          } else {
            create({
              resource: "settings",
              values: e,
            });
          }
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col>
            <CardAdmin title="">
              <Form.Item
                className="w-100"
                label={"Number of users to show Show/Hide switch:"}
                name="hra_users"
                rules={[
                  {
                    required: true,
                    type: "number",
                    min: 0,
                    message: "Number of users must be number and cannot be less than 0",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <i>If total of users who have completed the HRA are below this amount, the Hide/Show Switch will remain hidden. If over, the Hide/Show Switch will be displayed.</i>
            </CardAdmin>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};