import { DataType } from "types/challenge";
import { StatTypeChildProps } from "../StatType";
import "../updateStats.scoped.scss";
import { UploadProof } from "./UploadProof";

export const YesNoType: React.FC<StatTypeChildProps> = ({
  data,
  userResult,
  setUserResult,
  week,
  onUpdateChild,
  order,
  disabled,
  files,
  onRemoveFile,
  setLoading,
  loading,
  callback,
}) => {
  const answer = userResult?.[week]?.[order]?.user_answer;
  const onUpdate = async (value: number, user_answer: string) => {
    const _child = onUpdateChild?.(value, user_answer, data);
    const _userResult = {
      ...userResult,
      [week]: _child,
    };
    setUserResult(_userResult);
  };
  console.log("data challenge", data)
  return (
    <div className="single-activity">
      <hr className="single-activity-hr" />
      <div className="j-between single-activity-group">
        <div className="show-on-responsive one">
          <div className="left-col col">{data.title}</div>
          <div className="center-col col">{data.description}</div>
        </div>
        <div className="left-col col show-on-lg">{data.title}</div>
        <div className="center-col col show-on-lg">{data.description}</div>
        <div className="right-col j-between col buttons two">
          <button
            disabled={disabled}
            onClick={() => onUpdate(data?.results[DataType["Yes/No"]]?.[0]?.yes, "Yes")}
            className={`${answer === "Yes" ? "active cursor-not-allowed" : ""}`}
          >
            Yes
          </button>
          <button
            disabled={disabled}
            onClick={() => onUpdate(data?.results[DataType["Yes/No"]]?.[0]?.no, "No")}
            className={`${answer === "No" ? "active cursor-not-allowed" : ""}`}
          >
            No
          </button>
        </div>
        <UploadProof
          disabled={disabled}
          loading={loading}
          setLoading={setLoading}
          callback={callback}
          onRemoveFile={onRemoveFile}
          files={files}
          data={data}
        />
      </div>
    </div>
  );
};
