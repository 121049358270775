import React from "react";
import { Card, Col, DeleteButton, EditButton, Form, FormProps, List, Row, Table, useTable } from "@pankod/refine-antd";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { CrudFilters, HttpError } from "@pankod/refine-core";
interface DataType {
  key: React.Key;
  name: number;
  description: string;
  type: string;
  updateAt: string;
}

export const QuestionList: React.FC = () => {
  const { tableProps, searchFormProps } = useTable<any, HttpError, any>({
    initialSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
    onSearch: (params) => {
      let filters: CrudFilters = [];
      const { name, description } = params;
      filters.push({
        field: "name",
        operator: "eq",
        value: name ? parseInt(name) : undefined,
      });

      filters.push({
        field: "description",
        operator: "contains",
        value: description ?? undefined,
      });

      return filters;
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "No.",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (val: string) => {
        return <>{dayjs(val).format("YYYY-MM-DD HH:mm:ss")}</>;
      },
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton shape="circle" hideText recordItemId={record.id} />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="question" />
          </div>
        );
      },
      width: "10%",
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xl={18} xs={24}>
        <List title={<h1 style={{fontSize: 35, marginBottom:30, color:"rgb(4, 6, 10, 0.8)"}}>Questions</h1>}>
          <Table {...tableProps} rowKey="id" columns={columns} />
        </List>
      </Col>
      <Col
        xl={6}
        lg={24}
        xs={24}
        style={{
          marginTop: "48px",
        }}
      >
        <Card title={"Filters"}>
          <Filter formProps={searchFormProps} />
        </Card>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={"No."} name="name">
            <Input placeholder={"Search number"} prefix={<SearchOutlined />} type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={12}>
          <Form.Item label={"Description"} name="description">
            <Input.TextArea style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
