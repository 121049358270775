export enum NotificationType {
  Message = "message",
  MessageReport = "message_report",
  PostReport = "post_report",
  HRAReminderInApp = "hra_reminder_in_app",
  ChallengeReminderInApp = "challenge_reminder_in_app",
  ChallengeCongratsInApp = "challenge_congrats_in_app",
  ActivityReminderInApp = "activity_reminder_in_app",
  PostModerationNotice = "post_moderation_notice",
}
