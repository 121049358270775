import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import React, { useState, useEffect } from "react";
import "./account.scoped.scss";
import { useOne, useUpdate } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import EdiText from "react-editext";
import styled from "styled-components";
import { IUserUpdate } from "types/user";
import dayjs from "dayjs";
import { optionsGender } from "../Auth/SocialRegister/SocialRegister";
import { Select, Popconfirm } from "antd";
import { UploadImage } from "components/Upload/Upload";
import { Button, Form, Input, SaveButton, useSelect } from "@pankod/refine-antd";
import { config } from "config";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const StyledEdiText = styled(EdiText)`
  .react-answer-1 {
    align-items: center !important;
    text-align: center;
    color: #333;
    width: 100%;
    text-align: left;
  } 
  button {
    border-radius: 3px; 
  }
  button[editext="edit-button"] {
    color: #000;
    width: 50px;
    height: 30px;
    text-align: center;
    padding: 0 !important;
    background: white;
    color: #6d7381;

  }
  button[editext="save-button"] {
    background: white;
    margin: 0 7px 0 5px;
    padding: 3px 10px !important;
    color: #6d7381;
    &:hover {
      cursor:pointer;
      background: rgba(210,210,210,0.3);
    }
  }
  button[editext="cancel-button"] {
    background: white;
    padding: 3px 10px !important;
    color: #6d7381;
    &:hover {
      cursor:pointer;
      background: rgb(210,210,210,0.3);
    }
  }
  input, textarea  {
    border-radius: 3px;
    border: 1px solid rgba(210,210,210,1);
    padding: 3px 10px;
  }
  div[editext="view-container"], div[editext="edit-container"] {
    display: flex;
    justify-content: space-between
  }
  input.attrs(() => ({
    type: "Select",
  }))
  button {
    padding: 0 !important;
  }
`;
const ProfileNew: React.FC = () => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const options=[
    {value: 'weekly', label: 'Weekly'},
    {value: 'monthly', label: 'Monthly'},
    {value: 'quarterly', label: 'Quarterly'},
  ]
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [isEditingGender, setIsEditingGender] = React.useState(false);
  const [gender, setGender] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { me: user, company } = useMe();
  const { hra_summary } = useCompany();
  const [employeeDetails, setEmployeeDetails] = useState(true);

  const { data, refetch } = useOne({
    resource: "users",
    id: user?.id!,
    queryOptions: {
      enabled: Boolean(user?.id),
    },
  });

  const { mutateAsync: updateUser, isLoading } = useUpdate();
  const [formInfo] = Form.useForm();
  const userData = data?.data;
  async function onSave(data: IUserUpdate) {
    await updateUser({
      resource: "users",
      id: user?.id!,
      values: data,
    });
    return (
      <Popconfirm
        title="Delete the task"
        description="Are you sure to delete this task?"
        okText="Yes"
        cancelText="No"
      ></Popconfirm>
    );
  }

  const icon = user?.isChangedIcon ? config.ASSET_URL + user?.icon : user?.icon;

  const iconData = Form.useWatch("icon", form);

  const onChange = async (e: CheckboxChangeEvent) => {
    await updateUser({
      resource: "users",
      id: user?.id!,
      values: {
        hideMeDashboard: e.target.checked,
      },
    });
  };
  const onChangeChart = async (e: CheckboxChangeEvent) => {
    await updateUser({
      resource: "users",
      id: user?.id!,
      values: {
        user_chart_status: e.target.checked,
      },
    });
  };

  const { selectProps: selectPropsLocation } = useSelect({
    resource: "location",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const { selectProps: selectPropsTeam } = useSelect({
    resource: "team-company",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const { selectProps: selectPropsJobTitle } = useSelect({
    resource: "job-title",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const { selectProps: selectPropsEmployeeType } = useSelect({
    resource: "employee-type",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const professional_info = [
    {
      title: "Location",
      props: selectPropsLocation,
      placeholder: "Please choose your location",
      name: ["location", "id"],
      defaultField: "location_id",
    },
    {
      title: "Team",
      props: selectPropsTeam,
      placeholder: "Please choose your team",
      name: ["team_company", "id"],
      defaultField: "team_company_id",
    },
    {
      title: "Employee type",
      props: selectPropsEmployeeType,
      placeholder: "Please choose your employee type",
      name: ["employee_type", "id"],
      defaultField: "employee_type_id",
    },
    {
      title: "Job title",
      props: selectPropsJobTitle,
      placeholder: "Please choose your job title",
      name: ["job_title", "id"],
      defaultField: "job_title_id",
    },
  ];

  useEffect(() => {
    if (
      selectPropsEmployeeType?.options?.length === 0 &&
      selectPropsJobTitle?.options?.length === 0 &&
      selectPropsLocation?.options?.length === 0 &&
      selectPropsTeam?.options?.length === 0
    ) {
      setEmployeeDetails(true);
    } else {
      setEmployeeDetails(false);
    }
  }, [
    selectPropsEmployeeType?.options?.length,
    selectPropsJobTitle?.options?.length,
    selectPropsLocation?.options?.length,
    selectPropsTeam?.options?.length,
  ]);
  return (
    <div css={css`
      background-color: white;
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${hra_summary?.main_hra_color};
        border-color: ${hra_summary?.main_hra_color};
      }
      .ant-select {
        margin-bottom: 5px;
        width: 100%;
      }
      .ant-select-single .ant-select-selector {
        border-radius: 3px;
        width: 100%;
      }
      .ant-layout-content {
      background-color: red;
      }
      p {
        color: rgba(124, 124, 124, 0.7);
        font-size: 14px !important;
        font-weight: 300 !important;
      }
      .noti-content-left {
        width: 30%;
      }
      .noti-content-right {
        width: 55%;
      }
      .switch {
        width: 30%;
        text-align: left;
        padding-top: 3px;
      }
      .text {
        text-align: left;
        width: 70%;
        height: 100%;
      }
      .ant-switch.ant-switch-checked {
        background-color: ${hra_summary?.main_hra_color} !important;
      }
      .hr {
        border-top: 1px solid rgba(124, 124, 124, 0.2);
        margin: 20px 0;
      }
      .content {
        height: 40px;
      }
      .width100 {
        width: 100%;
      }
      .ava-save-button {
        margin: auto 0;
        text-align: center;
        padding: 5px 10px;
        background: white;
        color: #6d7381;
        border: 1px solid rgba(210,210,210,1);
        font-size: 14px;
        border-radius: 3px;
        margin-left: 20px;
      }
      @media (min-width: 578px) {
        margin-bottom: 20px !important;
      }
      .noti-content {
        @media (max-width: 578px) {
          display: block !important; 
          .noti-content-left, .noti-content-right {
            width: 100% !important;
          }
          .text {
            width: 100% !important;
          }
          .switch {
            margin-top: 15px !important;
            color: rgba(124, 124, 124, 0.7) !important;
          }
          .switch-hide-me {
            margin-top: 0 !important;
          }
          // .value {
          //   margin-top: auto !important;
          // }
          .react-answer-1 {
            align-items: center;
            margin-bottom: 0 !important;
          }
        }
      }
      h1 {
        @media (max-width:578px) {
          font-size: 22px !important;
        }
      }
      h3 {
        @media (max-width:578px) {
          margin: 0 0 0 0 !important;
        }
      }
      .hr1 {
        @media (max-width:578px) {
          margin: 40px 0 20px 0;
        }
      }
    `}>
      <h1>Account Center</h1>
      <p>Update basic info in your Good Life Account</p>
      <div className="hr"></div>
 
      {/* PROFILE */}
      <div className="noti-content j-between">
        <div className="noti-content-left">
          <h4>Profile</h4>
          <p>Edit your personal information and customize how others see your profile</p>
        </div>
        <div className="noti-content-right">
          {/* First name */}
          <div className="content j-between">
            <div className="switch">First name</div>
            <div className="text">
              <StyledEdiText
                type="text"
                inputProps={{
                  className: "textarea",
                  placeholder: "Please enter your first name ",
                  style: {
                    minWidth: "60%",
                  },
                  disabled: isLoading,
                  rows: 5,
                }}
                viewProps={{
                  className: "react-answer-1",
                  style: { margin: "0 0 10px 0" },
                }}
                saveButtonContent={<div className="res">{isMobile ? '✓' : 'Update'}</div>}
                cancelButtonContent={<div className="res cancel">{isMobile ? '✕' : 'Cancel'}</div>}
                value={userData?.firstname}
                onSave={(val: string) => onSave({ firstname: val })}
                editButtonContent="Edit"
                hideIcons={true}
              />
            </div>
          </div>
          {/* Last name */}
          <div className="content j-between">
            <div className="switch">Last name</div>
            <div className="text">
              <StyledEdiText
                type="text"
                inputProps={{
                  className: "textarea",
                  placeholder: "Please enter your last name",
                  style: {
                    minWidth: "60%",
                  },
                  rows: 5,
                }}
                viewProps={{
                  className: "react-answer-1",
                  style: { margin: "0 0 10px 0" },
                }}
                saveButtonContent={<div className="res">{isMobile ? '✓' : 'Update'}</div>}
                cancelButtonContent={<div className="res cancel">{isMobile ? '✕' : 'Cancel'}</div>}
                value={userData?.lastname}
                onSave={(val: string) => onSave({ lastname: val })}
                editButtonContent="Edit"
                hideIcons={true}
              />
            </div>
          </div>
          {/* Email */}
          <div className="content j-between">
            <div className="switch">Email</div>
            <div className="text">
              <div className="value">{userData?.email}</div>
            </div>
          </div>
          {/* Employer Code*/}
          <div className="content j-between">
            <div className="switch">Employer Code</div>
            <div className="text">
              <div>{userData?.employeeCode}</div>
            </div>
          </div>
          {/* Avatar */}
          <div className="content j-between" style={{paddingBottom: 100}}>
            <div className="switch">Avatar</div>
            <div className="text">
              <div>{user && (
              <Form
                form={form}
                className="flex"
                initialValues={{
                  icon: user.icon,
                }}
                onFinish={async () => {
                  await updateUser({
                    resource: "users",
                    id: user.id,
                    values: {
                      icon: iconData,
                      isChangedIcon: true,
                    },
                  });
                }}
              >
                <Form.Item hidden name="icon">
                  <Input />
                </Form.Item>
                <UploadImage
                  setLoading={setLoading}
                  loading={isLoading || loading}
                  isDefault={false}
                  form={form}
                  url={icon || ""}
                />
                {iconData !== user?.icon && (
                  <Button type="text" htmlType="submit" className="ava-save-button">
                    Save
                  </Button>
                )}
              </Form>
            )}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="hr hr1"></div>

      {/* EMAIL NOTIFICATION */}
      <div className="noti-content j-between">
        <div className="noti-content-left">
          <h4>Account</h4>
          <p>Update your account settings and manage security options for your login</p>
        </div>
        <div className="noti-content-right">
          {/* Username */}
          <div className="content j-between">
            <div className="switch">Username</div>
            <div className="text">
              <StyledEdiText
                type="text"
                inputProps={{
                  className: "textarea",
                  placeholder: "Please enter your username",
                  style: {
                    minWidth: "60%",
                  },
                  rows: 5,
                }}
                viewProps={{
                  className: "react-answer-1",
                  style: { margin: "0 0 10px 0" },
                }}
                saveButtonContent={<div className="res">{isMobile ? '✓' : 'Update'}</div>}
                cancelButtonContent={<div className="res cancel">{isMobile ? '✕' : 'Cancel'}</div>}
                value={userData?.username}
                onSave={(val: string) => onSave({ username: val })}
                editButtonContent="Edit"
                hideIcons={true}
              />
            </div>
          </div>
          {/* Date of birth */}
          <div className="content j-between">
            <div className="switch">Date of birth</div>
            <div className="text">
              <StyledEdiText
                type="date"
                inputProps={{
                  className: "textarea",
                  placeholder: "Please enter date of birth",
                  style: {
                    minWidth: "60%",
                  },
                  rows: 5,
                }}
                viewProps={{
                  className: "react-answer-1",
                  style: { margin: "0 0 10px 0" },
                }}
                saveButtonContent={<div className="res">{isMobile ? '✓' : 'Update'}</div>}
                cancelButtonContent={<div className="res cancel">{isMobile ? '✕' : 'Cancel'}</div>}
                value={dayjs(userData?.dateOfBirth).format("MM/DD/YYYY")}
                onSave={(val: string) => onSave({ dateOfBirth: new Date(val) })}
                editButtonContent="Edit"
                hideIcons={true}
              />
            </div>
          </div>
          {/* Birth sex */}
          <div className="content j-between">
            <div className="switch">Birth Sex</div>
            <div className="text j-between" style={{width:"70%"}}>
            {isEditingGender && (
                <Select
                  onChange={(val) => {
                    setGender(val);
                  }}
                  defaultValue={userData?.gender}
                  options={optionsGender}
                  placeholder={"Please enter your gender"}
                  style={{width: "100%"}}
                />
              )}
              <p className={`value ${isEditingGender ? "" : "width100"}`}>
              <StyledEdiText
                type="date"
                inputProps={{
                  className: `textarea ${isEditingGender ? "hidden" : ""}`,
                  placeholder: "Please enter your gender",
                  style: {
                    minWidth: "60%",
                    color: "#333 !important"
                  },
                  rows: 5,
                }}
                viewProps={{
                  className: "react-answer-1",
                  style: { margin: "0 0 10px 0", color: "#333 !important" },
                }}
                saveButtonContent={<div className="res">{isMobile ? '✓' : 'Update'}</div>}
                cancelButtonContent={<div className="res cancel">{isMobile ? '✕' : 'Cancel'}</div>}
                editButtonContent="Edit"
                hideIcons={true}
                onCancel={() => {
                  setIsEditingGender(false);
                }}
                value={userData?.gender}
                onSave={() => {
                  gender && onSave({ gender });
                  setIsEditingGender(false);
                }}
                onEditingStart={() => {
                  setIsEditingGender(true);
                }}
              />
              </p>
            </div>
          </div>
          {/* Hide me from Leaderboard */}
          <div className="content j-between" css={css`
            @media (max-width:788px) {
              display: none !important;
            }
            `}>
            <div className="text" css={css`
              width: 30px !important;
            `}>
              <div className="value"><Checkbox checked={userData?.hideMeDashboard} onChange={onChange} className="checkbox"></Checkbox></div>
            </div>
            <div className="switch switch-hide-me" style={{width:"100%", marginLeft: 0}}>Hide me from Leaderboard</div>
          </div>
        </div>
      </div>

    </div>
  ) 
}
export default ProfileNew;
