import { useForm, Form, Input, Create, InputNumber, Select, useSelect } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import React from "react";
import { useCompany } from "context/CompanyContext";

export const TopicCreate: React.FC = () => {
  const { push } = useNavigation();
  const { id: company_id } = useCompany();
  const { formProps, saveButtonProps } = useForm({
    resource: "topic",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/chat/forum/topic");
    },
  });
  const { selectProps } = useSelect({
    resource: "category-forum",
    optionLabel: "name",
    optionValue: "id",
    // filters: [
    //   {
    //     field: "category.company_id",
    //     operator: "eq",
    //     value: company_id,
    //   },
    // ],
    // queryOptions: {
    //   enabled: Boolean(company_id),
    // },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          className="w-100"
          label={"Name"}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Category"}
          name={["category", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
          className="w-full"
        >
          <Select {...selectProps} placeholder="category" />
        </Form.Item>
        <Form.Item className="w-100" label={"Order"} name="order">
          <InputNumber />
        </Form.Item>
      </Form>
    </Create>
  );
};
