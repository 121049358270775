import { List } from "@pankod/refine-antd";
import React from "react";
import IconList from "./list";

export const Icons: React.FC = () => {
  return (
    <List title={<h1 style={{fontSize: 35, marginBottom:30, color:"rgb(4, 6, 10, 0.8)"}}>Celebration Icons</h1>}>
      <div className="bg-white p-4">
        <IconList />
      </div>
    </List>
  );
};
