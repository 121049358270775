import React from "react";
import { useList } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import { useCompany } from "context/CompanyContext";
import { Table } from 'antd';

const columns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank'
  },
  {
    title: 'Trophy',
    dataIndex: 'trophy',
    key: 'trophy'
  },
  {
    title: 'Users',
    dataIndex: 'users',
    key: 'users'
  }
]

const UserTrophyAchievement: React.FC = () => {
  const { company } = useMe();
  const { data: dataUserTrophy } = useList({
    resource: "trophy-count",
    config: {
      filters: [
        {
          field: "employee_code",
          operator: "eq",
          value: company?.code,
        },
      ],
    },
  });
  const UserTrophy = dataUserTrophy?.data;
  //console.log({UserTrophy})
  return (
    <div style={{padding: "0 30px"}}>
      <Table dataSource={UserTrophy} columns={columns}/>
    </div>
  );
}

export default UserTrophyAchievement;
