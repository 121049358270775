import { List } from "@pankod/refine-antd";
import React from "react";
import IconList from "./list";

export const ActivityIndividual: React.FC = () => {
  return (
    <List title="Activity Individual">
      <div className="bg-white p-4">
        <IconList />
      </div>
    </List>
  );
};
