import { Form, Input, InputNumber } from "@pankod/refine-antd";
import { FormListFieldData } from "antd/es/form/FormList";
import { DataType } from "types/challenge";
import React from "react";
interface ResultProps {
  fieldR: FormListFieldData;
  field: FormListFieldData;
  parentName?: string;
  category?: string;
  type?: string;
}

export const ResultType: React.FC<ResultProps> = (props) => {
  const { field, type: _type } = props;
  const parentName = props.parentName || "stats";
  const type = Form.useWatch([parentName, field.name, "data_type"]) || _type;
  let Component: JSX.Element = <></>;

  switch (type) {
    case DataType.Numeric:
      Component = <NumericForm {...props} />;
      break;
    case DataType["Yes/No"]:
      Component = <YesNoForm {...props} />;
      break;
    case DataType.Dropdown:
      Component = <DropdownForm {...props} />;
      break;
    case DataType.Date:
      Component = <DateForm {...props} />;
      break;
    default:
      Component = <FreeTextForm {...props} />;
  }
  return Component;
};

const NumericForm: React.FC<ResultProps> = ({ fieldR }) => {
  return (
    <div className="flex space-x-2">
      <Form.Item
        rules={[
          {
            required: true,
            message: "From is required!",
          },
        ]}
        label={`From`}
        name={[fieldR.name, "from"]}
        className="w-full"
      >
        <InputNumber type="number" className="font-normal w-full" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "To is required!",
          },
        ]}
        label={`To`}
        name={[fieldR.name, "to"]}
        className="w-full"
      >
        <InputNumber type="number" className="font-normal w-full" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Point is required!",
          },
        ]}
        label={`Point`}
        name={[fieldR.name, "point"]}
        className="w-full"
      >
        <InputNumber type="number" className="font-normal w-full" />
      </Form.Item>
    </div>
  );
};

const FreeTextForm: React.FC<ResultProps> = ({ fieldR }) => {
  return (
    <div className="flex space-x-2">
      <Form.Item
        rules={[
          {
            required: true,
            message: "Answer is required!",
          },
        ]}
        label={`Answer`}
        name={[fieldR.name, "answer"]}
        className="w-full"
      >
        <InputNumber type="number" className="font-normal w-full" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "No answer is required!",
          },
        ]}
        label={`No answer`}
        name={[fieldR.name, "no_answer"]}
        className="w-full"
      >
        <InputNumber type="number" className="font-normal w-full" />
      </Form.Item>
    </div>
  );
};

const YesNoForm: React.FC<ResultProps> = ({ fieldR }) => {
  return (
    <div className="flex space-x-2">
      <Form.Item
        rules={[
          {
            required: true,
            message: "Yes is required!",
          },
        ]}
        label={`Yes`}
        name={[fieldR.name, "yes"]}
        className="w-full"
      >
        <InputNumber type="number" className="font-normal w-full" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "No is required!",
          },
        ]}
        label={`No`}
        name={[fieldR.name, "no"]}
        className="w-full"
      >
        <InputNumber type="number" className="font-normal w-full" />
      </Form.Item>
    </div>
  );
};

const DropdownForm: React.FC<ResultProps> = ({ fieldR, category }) => {
  const shouldShowPoint = category !== "activity";
  return (
    <div className="flex space-x-2">
      <Form.Item
        rules={[
          {
            required: true,
            message: "Answer is required!",
          },
        ]}
        label={`Answer`}
        name={[fieldR.name, "answer"]}
        className="w-full"
      >
        <Input className="font-normal w-full" />
      </Form.Item>
      {shouldShowPoint && (
        <Form.Item
          rules={[
            {
              required: true,
              message: "Point is required!",
            },
          ]}
          label={`Point`}
          name={[fieldR.name, "point"]}
          className="w-full"
        >
          <InputNumber type="number" className="font-normal w-full" />
        </Form.Item>
      )}
    </div>
  );
};

const DateForm: React.FC<ResultProps> = ({ fieldR }) => {
  return (
    <div className="flex space-x-2">
      <Form.Item
        rules={[
          {
            required: true,
            message: "required!",
          },
        ]}
        label={`Select`}
        name={[fieldR.name, "tick"]}
        className="w-full"
      >
        <InputNumber type="number" className="font-normal w-full" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "required!",
          },
        ]}
        label={`No select`}
        name={[fieldR.name, "no_tick"]}
        className="w-full"
      >
        <InputNumber type="number" className="font-normal w-full" />
      </Form.Item>
    </div>
  );
};
