import { Avatar, DeleteButton, EditButton, List, Table, useTable, Space } from "@pankod/refine-antd";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useMe } from "context/MeContext";
import dayjs from "dayjs";
import React from "react";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import type { ColumnType } from "antd/es/table"; // Import necessary types

interface DataType {
  id: number;
  name: string;
  email: string;
  username: string;
  employeeCode: string;
  date: string;
  gender: string;
  firstname: string;
}
export const Admin: React.FC = () => {
  const { isAdmin, isSuperAdmin, company } = useMe();
  const { tableProps } = useTable<any, HttpError, any>({
    resource: "users",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    initialFilter: [
      {
        field: "email",
        operator: "contains",
        value: "",
      },
      {
        field: "username",
        operator: "contains",
        value: "",
      },
      {
        field: "firstname",
        operator: "contains",
        value: "",
      },
      {
        field: "employeeCode",
        operator: "contains",
        value: "",
      },
    ],
    onSearch: (params) => {
      let filters: CrudFilters = [];
      const { email, username, employeeCode, firstname } = params;

      if (email) {
        filters.push({
          field: "email",
          operator: "contains",
          value: email,
        });
      }

      if (username) {
        filters.push({
          field: "username",
          operator: "contains",
          value: username,
        });
      }

      if (firstname) {
        filters.push({
          field: "firstname",
          operator: "contains",
          value: firstname,
        });
      }

      if (employeeCode) {
        filters.push({
          field: "employeeCode",
          operator: "contains",
          value: employeeCode,
        });
      }

      return filters;
    },
    permanentFilter: !isSuperAdmin
      ? [
          {
            field: "companyId",
            operator: "eq",
            value: company?.id,
          },
        ]
      : [],
  });

  const columns = [
    {
      title: "Avatar",
      dataIndex: "icon",
      key: "icon",
      render: (val: string) => {
        return <Avatar src={val} />;
      },
      width: "5%",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: true,
      ...getColumnSearchProps("username"),
    },
    {
      title: "Name",
      dataIndex: "firstname",
      key: "firstname",
      sorter: true,
      render: (val: string, record: any) => {
        return (
          <>
            {val} {record.lastname}
          </>
        );
      },
      ...getColumnSearchProps("firstname"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      ...getColumnSearchProps("email"),
    },
    // {
    //   title: "Employer",
    //   dataIndex: ["__company__", "name"],
    //   key: "Company",
    // },
    {
      title: "EC",
      dataIndex: "employeeCode",
      key: "employeeCode",
      sorter: true,
      ...getColumnSearchProps("employeeCode"),
    },
    {
      title: "DOB",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (val: string) => {
        return <>{val ? dayjs(val).format("YYYY-MM-DD") : "-"}</>;
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (val: string) => {
        return <>{dayjs(val).format("YYYY-MM-DD")}</>;
      },
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton shape="circle" hideText recordItemId={record.id} disabled={!(isAdmin || isSuperAdmin)} />
            <DeleteButton
              disabled={!(isAdmin || isSuperAdmin)}
              shape="circle"
              hideText
              recordItemId={record.id}
              resourceNameOrRouteName="users"
            />
          </div>
        );
      },
    },
  ];

  return (
    // <div>
    //   <Col
    //     xl={6}
    //     lg={24}
    //     xs={24}

    //   >
    //     <Card title={"Filters"}>
    //       <Filter formProps={searchFormProps} />
    //     </Card>
    //   </Col>

    //   <Col>
    //     <List title="Users" canCreate>
    //       <Table {...tableProps} rowKey="id" columns={columns}/>
    //     </List>
    //   </Col>
    // </div>
      <List title={<h1 style={{fontSize: 35, marginBottom:30, color:"rgb(4, 6, 10, 0.8)"}}>Users</h1>} canCreate>
        <Table {...tableProps} rowKey="id" columns={columns}/>
      </List>
  );
};

// const Filter: React.FC<{ formProps: FormProps }> = (props) => {
//   return (
//     <Form layout="vertical" {...props.formProps}>
//       <Row gutter={[10, 0]} align="bottom">
//         <Col xs={24} xl={24} md={12}>
//           <Form.Item label={"Email"} name="email">
//             <Input placeholder={"Search for email"} prefix={<SearchOutlined />}/>
//           </Form.Item>
//         </Col>
//         <Col xs={24} xl={24} md={8}>
//           <Form.Item>
//             <Button style={{ width: "100%" }} htmlType="submit" type="primary">
//               Search
//             </Button>
//           </Form.Item>
//         </Col>
//       </Row>
//     </Form>
//   );
// };

const getColumnSearchProps = (dataIndex: keyof DataType): ColumnType<DataType> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search`}
        value={selectedKeys[0] as string}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters?.();
            setSelectedKeys([]);
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
  onFilter: (value: string | number | boolean | React.Key, record: DataType) => {
    const text = record[dataIndex];
    // return text
    //     ? text.toString().toLowerCase().includes((value as string).toLowerCase())
    //     : false;
    return text
      ? text
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
      : false;
  },
  render: (text: string) => text,
});
