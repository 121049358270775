import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
  LabelList,
  ResponsiveContainer
} from "recharts";
import { useList, useTable } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
interface GenderScores {
  age_group: string;
  male: number | null;
  female: string | null;
  // median_score: number | null;
}
const data = [
  {
    age_group: "18-25",
    male: -0.1,
    female: 0.80,
  },
  {
    age_group: "26-35",
    male: 0.22,
    female: 0.36,
  },
  {
    age_group: "36-45",
    male: 0.24,
    female: 0.29,
  },
  {
    age_group: "46-55",
    male: 0.27,
    female: 0.44,
  },
  {
    age_group: "56-65",
    male: 0.36,
    female: 0.46,
  },
  {
    age_group: "65+",
    male: 0.68,
    female: 0.29,
  },
];

export default function MedianWellnessScoreByDemographic() {
  const { company } = useMe();
  const { data: dataDemographic } = useList({
    resource: "demographic-agg-scores",
    config: {
      filters: [
        {
          field: "employee_code",
          operator: "eq",
          value: company?.code,
        },
      ],
    },
  });
  const demographic = dataDemographic?.data;
  //console.log({ demographic });

  const organizedData: Record<string, GenderScores> = {};
  demographic?.forEach((record:any) => {
    const { age_group, gender, median_score } = record;
    console.log("record:::::", record);

    if (!organizedData[age_group]) {
      organizedData[age_group] = { age_group, male: null, female: null};
    }

    if (gender === "Male") {
      organizedData[age_group].male = median_score;
    } else if (gender === "Female") {
      organizedData[age_group].female = median_score;
    }
    
  });
  const barChartData = Object.values(organizedData);
  //console.log({barChartData});
  return (
    //<ResponsiveContainer minWidth={850} height={350}>
    <BarChart
      layout="vertical"
      width={850}
      height={450}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      barCategoryGap={12}
      barGap={10}
      
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis 
        type="number" 
        domain={['auto', 'auto']}
        allowDataOverflow 
        tickFormatter={(tick) => tick}
      />
      <YAxis type="category" dataKey="age_group" domain={['auto', 'auto']}/>
      <Tooltip />
      <Bar dataKey="male" fill="#8C2E2E" activeBar={<Rectangle stroke="#333" />}>
        <LabelList dataKey="male" position="right" style={{ fontSize: 12 }} />
      </Bar>
      <Bar dataKey="female" fill="#005E83" activeBar={<Rectangle  stroke="#333" />}>
        <LabelList dataKey="female" position="right" style={{ fontSize: 12 }} />
      </Bar>
      <Legend />
    </BarChart>
    //</ResponsiveContainer>
  );
}
