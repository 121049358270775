import React from "react";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  PolarRadiusAxis,
  PolarAngleAxis,
} from "recharts";
import Users1 from "../../../../assets/img/users.svg"
const data = [
  {
    name: "participated",
    percent: 80,
    fill: "#005E83",
  },
];
export default function ChallengeParticipated() {
  return (
    <RadialBarChart
      width={250}
      height={260}
      cx="50%"
      cy="45%"
      innerRadius={100}
      outerRadius={150}
      barSize={20}
      data={data}
      startAngle={180} 
      endAngle={0}
    >
      <RadialBar
        label={({ cx, cy, value }) => (
          <>
            <image
              href={Users1}  
              x={cx - 30 / 2}  
              y={cy - 60}  
              height={30}  
              width={30} 
              //clipPath="circle(50%)"
              style={{
                filter: "grayscale(100%) brightness(60%)", 
                opacity: 0.3,
              }}
            />
            <text
              x={cx}
              y={cy-15}
              fill="rgba(124, 124, 124, 0.7)"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={15}
              fontWeight={400}
            >
              186 Total users
            </text>
            <text
              x={cx}
              y={cy+20}
              fill="#005E83"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={35}
              fontWeight={400}
            >
              {`${value}%`}
            </text>
          </>
        )}
        background
        //clockWise
        dataKey="percent"
        // max
      />
      <PolarAngleAxis
        tick={false}
        domain={[0, 100]}
        type="number"
      />
    </RadialBarChart>
  );
}
