import React from "react";
import { Layout as AntLayout, Grid } from "antd";
import { RefineLayoutLayoutProps } from "@pankod/refine-ui-types";
import { Sider } from "../Sider";
import { Header } from "../Header";
import { useMe } from "context/MeContext";
import { Role } from "types/user";
import { useNavigation } from "@pankod/refine-core";
import { LoadingFullPage } from "components/Loading/FullPage";

export const Layout: React.FC<RefineLayoutLayoutProps> = ({ children, OffLayoutArea }) => {
  const { myRole, isMeLoading, isSuperAdmin } = useMe();
  const { push } = useNavigation();
  React.useEffect(() => {
    const isGuest = myRole === Role.GUEST;
    if (isGuest && !isMeLoading && !isSuperAdmin) {
      push("/");
    }
  }, [myRole, push, isMeLoading, isSuperAdmin]);
  const breakpoint = Grid.useBreakpoint();
  const isSmall = typeof breakpoint.sm === "undefined" ? true : breakpoint.sm;
  return isMeLoading ? (
    <LoadingFullPage />
  ) : (
    <AntLayout style={{ minHeight: "100vh", flexDirection: "row" }}>
      <Sider />
      <AntLayout
        style={{
          //backgroundColor: "#ccdfe6",
          backgroundColor: "white",
        }}
      >
        <Header />
        <AntLayout.Content>
          <div
            style={{
              padding: isSmall ? 24 : 12,
              paddingTop: 0,
              minHeight: 360,
            }}
          >
            {children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntLayout.Content>
      </AntLayout>
    </AntLayout>
  );
};
