import React, { useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Switch, Tooltip } from "antd";
import { useMe } from "context/MeContext";
import { capitalize, sumBy } from "lodash-es";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import "../../App.scss";
import "./hra.scoped.scss";
import { useList } from "@pankod/refine-core";

export const HRASection: React.FC = () => {
  const { totalScore, worstDimensions, totalHRA, totalUsers } = useMe();
  const { hra_summary } = useCompany();
  const status = hra_summary?.company_chart_status;
  const [averageIndex, setAverageIndex] = useState(status);
  const { data: dataRawSettings } = useList({
    resource: "settings",
  });
  const setting = dataRawSettings?.data[0];
  const shouldShowAverage = totalUsers >= setting?.hra_users;

  const worstDimentionIds = worstDimensions?.map((item) => item.id);
  const dataTum =
    totalScore
      ?.filter((item: any) => worstDimentionIds?.includes(item?.dimensionId) || null)
      .map((r: any) => {
        const name = worstDimensions?.find((item) => item?.id === r?.dimensionId)?.name;
        const average = totalHRA?.find((item) => item?.dimensionId === r?.dimensionId)?.scoring.toFixed(1) || 0;
        let s = r.scoring;
        if (s === 0) {
          s = s + 0.1;
        }
        return {
          country: capitalize(name || ""),
          You: s,
          YouColor: "hsl(5, 70%, 50%)",
          Average: average,
          AverageColor: "hsl(55, 70%, 50%)",
        };
      }) || [];

  const mySumHRA = sumBy(totalScore, "scoring") || 0;
  const totalSumHRA = sumBy(totalHRA, "scoring") || 0;

  const dataSum = [
    {
      score: "Total Wellness Score",
      You: (mySumHRA / 8).toFixed(1),
      YouColor: "hsl(5, 70%, 50%)",
      Average: (totalSumHRA / 8).toFixed(1),
      AverageColor: "hsl(55, 70%, 50%)",
    },
  ];
  const colorSchema: any = hra_summary?.chart_color_scheme || "dark2";
  return (
    <div
      className="chart-box hra-summary"
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      <h6
        className="chart-title"
        css={css`
          color: rgba(4, 6, 10, 0.8) !important;
          @media (max-width: 768px) {
            margin: 0;
            padding: 0;
            line-height: 0;
          }
        `}
      >
        HRA SUMMARY
      </h6>

      {shouldShowAverage && (
        <Tooltip title="Click to show and hide average results">
          <div
            className="show-switch"
            css={css`
              position: absolute;
              bottom: 30px;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 100;
              .ant-switch.ant-switch-checked {
                background-color: ${hra_summary?.main_hra_color} !important;
              }
            `}
          >
            <Switch
              checkedChildren="Hide"
              unCheckedChildren="Show"
              checked={averageIndex}
              onChange={(checked) => setAverageIndex(checked)}
            ></Switch>
          </div>
        </Tooltip>
      )}

      <div className="j-between hra-charts">
        <div style={{ height: 500, width: "50%", margin: "10px" }} className="single-chart">
          <ResponsiveBar
            data={dataTum}
            keys={["You", averageIndex === true ? "Average" : ""]}
            indexBy="country"
            margin={{ top: 50, right: 60, bottom: 70, left: 60 }}
            padding={0.4}
            minValue={-10}
            maxValue={10}
            groupMode="grouped"
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: colorSchema }}
            theme={{ fontSize: 14 }}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 5,
                itemWidth: 140,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [{ on: "hover", style: { itemOpacity: 1 } }],
              },
            ]}
            role="application"
            barAriaLabel={function (e) {
              return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
            }}
            labelTextColor="#333"
            label={(d) => (<tspan y={-8}>{d.value}</tspan>) as unknown as string}
            axisBottom={{
              tickSize: 10,
              tickPadding: 10,
              tickRotation: 0,
              legend: "Areas needing the most support",
              legendPosition: "middle",
              legendOffset: 60,
            }}
          />
        </div>
        <div style={{ height: 500, width: "50%", margin: "10px" }} className="single-chart">
          <ResponsiveBar
            data={dataSum}
            keys={["You", averageIndex === true ? "Average" : ""]}
            indexBy="score"
            margin={{ top: 50, right: 60, bottom: 70, left: 60 }}
            padding={0.2}
            minValue={-10}
            maxValue={10}
            groupMode="grouped"
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: colorSchema }}
            theme={{ fontSize: 14 }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 5,
                itemWidth: 140,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [{ on: "hover", style: { itemOpacity: 1 } }],
              },
            ]}
            role="application"
            ariaLabel="total wellness score"
            barAriaLabel={function (e) {
              return e.id + ": " + e.formattedValue + " in score: " + e.indexValue;
            }}
            labelTextColor="#333"
            label={(d) => (<tspan y={-8}>{d.value}</tspan>) as unknown as string}
          />
        </div>
      </div>
    </div>
  );
};
