import { ResponsiveBar } from "@nivo/bar";
import imgWellness from "../../assets/img/yoga.png";
import React from "react";
import { useMe } from "context/MeContext";
import { capitalize } from "lodash-es";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { generateURL } from "utils/generateURL";
import { BgColorsOutlined } from "@ant-design/icons";
import { Button } from "antd" 
import routerProvider from "@pankod/refine-react-router-v6";
import "./risk.scoped.scss"

const { Link } = routerProvider; 

export const RiskAreaImage: React.FC = () => {
  const { dimensions, totalScore, myHRA } = useMe();
  const { hra_summary, risk_areas, path: company_path } = useCompany();
  const dataTum =
    totalScore?.map((r) => {
      const name = dimensions?.find((item) => item?.id === r?.dimensionId)?.name;
      let s = r?.scoring;
      if (s === 0) {
        s = s + 0.1;
      }
      return {
        country: capitalize(name || ""),
        id: r?.dimensionId,
        measure: s,
        color: s > 0 ? "hsl(128, 70%, 50%)" : "hsl(297, 70%, 50%)",
        text: messages.find((item) => item?.name === name?.toLowerCase())?.text || "",
      };
    }) || [];
    const color = hra_summary?.title_color;
    const barColor = risk_areas?.title_color;
    const barColor2 = risk_areas?.title_color2

  return (
    <div className="j-between" css={css`
      margin-top: 2rem;
      @media (max-width: 768px) {
        margin-top: 1rem;
      }
    `}>
      <div className="chart risk-areas chart-box desktop" style={{ width: "55%" }}> 
        {typeof myHRA !== 'object' ? (
              <Link to={`/${company_path}/hra`} className="disabled-chart">
                <div className="disabled-group">
                  <p>Curious about diving deeper into your health stats? Take the HRA!</p>
                  <Button size="large">Let's Go!</Button>
                </div>
              </Link>
        ) : null}
        <div className="risk-chart" style={{ height: 450, width: "100%", margin: "0" }}>
          <h6
            className="chart-title"
            css={css`
              color: rgba(4, 6, 10, 0.8) !important;
              width:100%;
            `}
          >
            RISK AREAS
          </h6>
          <span css={css`
            position: relative;
          `}>
          <ResponsiveBar
            data={dataTum}
            keys={["measure"]}
            indexBy="country"
            margin={{ top: 20, right: 20, bottom: 50, left: 150 }}
            padding={0.2}
            minValue={-10}
            maxValue={10}
            layout="horizontal"
            colors={(d) => (d.data.measure > 0 ? barColor : barColor2)}
            theme={{ fontSize: 16, grid: { line: { stroke: "rgba(80, 80, 80, 0.4)" } } }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
            }}
            axisBottom={{
              tickPadding: 10,
            }}
            tooltip={({ id, value, data }) => (
              <div
                className="tooltip"
                style={{
                  backgroundColor: "rgba(256,256,256,0.8)",
                  padding: "1rem",
                  border: "1px solid",
                  borderColor: color,
                  borderRadius: "10px",
                }}
              >
                <strong style={{ color: color }}>
                  Your {data.country} is {value}.
                </strong>
                <div className="tooltip-data">{data.text}</div>
              </div>
            )}
            enableGridX={true}
            enableGridY={true}
            gridXValues={1}
            ariaLabel="Risk Areas"
            label={(d) =>
              d.data.measure > 0
                ? ((
                    <tspan
                      x={-15}
                      style={{
                        fill: barColor,
                        fontSize: 14,
                        stroke: "white",
                        strokeWidth: "0.5em",
                        paintOrder: "stroke",
                      }}
                    >
                      <tspan>{d.value}</tspan>
                    </tspan>
                  ) as unknown as string)
                : ((
                    <tspan
                      x={-20}
                      width="100%"
                      style={{ fill: barColor2, fontSize: 14, stroke: "white", strokeWidth: "0.5em", paintOrder: "stroke" }}
                    >
                      <tspan>{d.value}</tspan>
                    </tspan>
                  ) as unknown as string)
            }
          />
          {/* <div css={css`
            text-align:center;
            color: gray;
            font-style: italic;
            font-size: 13px;
          `}>Hover over each bar to reveal insightful explanations about each dimension.</div> */}
          </span>
        </div>
      </div>
      <div className="chart-box risk-area risk-area-img" style={{ width: "42%", marginLeft: "1rem" }}>
        <img
          src={generateURL(risk_areas?.image_url)}
          alt=""
          style={{ margin: "auto", alignContent: "center", alignSelf: "center", borderRadius: 10 }}
        />
      </div>

      {/* Responsive */}
      <div className="risk-areas chart-box responsive" style={{ width: "100%" }}>
      {typeof myHRA !== 'object' ? (
              <Link to={`/${company_path}/hra`} className="disabled-chart">
                <div className="disabled-group">
                  <p>Curious about diving deeper into your health stats? Take the HRA!</p>
                  <Button size="large">Let's Go!</Button>
                </div>
              </Link>
        ) : null}
        <div className="risk-chart" style={{ height: 350, width: "300px", margin: "0 auto", padding: "30px 0 30px 3px"}}>
          <h6
            className="chart-title"
            css={css`
              color: ${hra_summary?.title_color} !important;
              background-color: yellow;
            `}
          >
            RISK AREAS
          </h6>
          <ResponsiveBar
            data={dataTum}
            keys={["measure"]}
            indexBy="country"
            margin={{ top: 15, right: 20, bottom: 50, left: 90 }}
            padding={0.2}
            minValue={-10}
            maxValue={10}
            layout="horizontal"
            colors={(d) => (d.data.measure > 0 ? barColor : barColor2)}
            theme={{ fontSize: 12, grid: { line: { stroke: "rgba(80, 80, 80, 0.4)" } } }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: 0,
            }}
            axisBottom={{
              tickPadding: 10,
            }}
            tooltip={({ id, value, data }) => (
              <div
                className="tooltip"
                style={{
                  backgroundColor: "rgba(256,256,256,0.8)",
                  padding: "1rem",
                  border: "1px solid",
                  borderColor: color,
                  borderRadius: "10px",
                }}
              >
                <strong style={{ color: color }}>
                  Your {data?.country} is {value}.
                </strong>
                <div className="tooltip-data">{data?.text}</div>
              </div>
            )}
            enableGridX={true}
            enableGridY={true}
            gridXValues={1}
            ariaLabel="Risk Areas"
            label={(d) =>
              d?.data?.measure > 0
                ? ((
                    <tspan
                      x={-15}
                      style={{
                        fill: barColor,
                        fontSize: 14,
                        stroke: "white",
                        strokeWidth: "0.5em",
                        paintOrder: "stroke",
                      }}
                    >
                      <tspan>{d?.value}</tspan>
                    </tspan>
                  ) as unknown as string)
                : ((
                    <tspan
                      x={-20}
                      width="100%"
                      style={{ fill: barColor2, fontSize: 12, stroke: "white", strokeWidth: "0.5em", paintOrder: "stroke" }}
                    >
                      <tspan>{d?.value}</tspan>
                    </tspan>
                  ) as unknown as string)
            }
          />
          
        </div>
      </div>
    </div>
  );
};

const messages = [
  {
    name: "spiritual",
    text: "Spiritual Risk is based on HRA answers about stress management, sleep, burnout, and emotional support. Improving this score fosters inner peace and tranquility and connection to the world around you.",
  },
  {
    name: "occupational",
    text: "Occupational Risk is determined by HRA responses about job satisfaction, work-life balance, and career goals. Improving this score increases fulfillment and satisfaction in work and helps achieve better work-life balance.",
  },
  {
    name: "intellectual",
    text: "Intellectual Risk is influenced by HRA responses about sleep, alcohol, stress, and burnout. Improving this score sharpens the mind and boosts creativity.",
  },
  {
    name: "environmental",
    text: "Environmental Risk is influenced by HRA responses about hazards and lifestyle habits. Improving this score leads to a more sustainable lifestyle and protects from unexpected harm.",
  },
  {
    name: "financial",
    text: "Financial Risk is based on HRA answers about preventive healthcare, food spend, tobacco, work-life balance, and burnout. Improving this score reduces financial stress and increases financial security.",
  },
  {
    name: "social",
    text: "Social Risk is determined by HRA answers about social support, work-life balance, alcohol, and burnout. Improving this score builds stronger relationships, fosters belonging and connection with others.",
  },
  {
    name: "emotional",
    text: "Emotional Risk is based on HRA answers about mood, sleep, stress, diet, alcohol, and work-life balance. Improving this score builds resilience, manages stress effectively, and enhances overall emotional well-being.",
  },
  {
    name: "physical",
    text: "Physical Risk is determined by responses to the HRA about weight, diet, exercise, tobacco and alcohol use, and stress management. Improving this score can help you lead a healthier lifestyle, and reduce risk of chronic diseases.",
  },
];
