import React from "react";
import { css } from "@emotion/react";
import { Switch, Select, Form, Input } from "antd";
import { useCompany } from "context/CompanyContext";
import { useForm } from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import { initialValues } from "pages/MyAccount2024/Notifications";

export const Notifications: React.FC = () => {
  const { hra_summary } = useCompany();
  const { me: user } = useMe();

  const onChange = () => {
    formProps.form?.submit();
  };
  const handleChange = () => {
    formProps.form?.submit();
  };
  const options = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quaterly" },
  ];
  const { data } = useList<any>({
    resource: "settings",
  });
  const setting = data?.data[0];

  const { formProps, formLoading } = useForm({
    resource: "settings",
    id: setting?.id,
    redirect: false,
    action: "edit",
  });
  console.log({ setting });
  return (
    <div
      css={css`
        background-color: white;
        .ant-layout-content {
          background-color: red;
        }
        p {
          color: rgba(124, 124, 124, 0.7);
        }
        .noti-content {
          padding: 0 50px 0 0px;
        }
        .noti-content-left {
          width: 20%;
        }
        .noti-content-right {
          width: 65%;
        }
        .switch {
          width: 11%;
          text-align: left;
          padding-top: 3px;
        }
        .text {
          text-align: left;
          width: 70%;
          .noti {
            font-weight: 500;
            text-align: left;
          }
          p {
            text-align: left;
          }
        }
        .select {
          width: 25%;
          padding-top: 10px;
          backrground-color: yellow;
        }
        .ant-switch.ant-switch-checked {
          background-color: ${hra_summary?.main_hra_color} !important;
        }
        .hr {
          border-top: 1px solid rgba(124, 124, 124, 0.2);
          margin: 20px 0;
        }
      `}
    >
      <h1>Notification settings</h1>
      <p>Select the kind of notifications users get about their activities and recommendations</p>
      <div className="hr"></div>
      <Form
        {...formProps}
        initialValues={setting || initialValues}
        onFinish={(values: any) => {
          console.log({ values });
          values.user_id = user?.id;
          formProps.onFinish?.(values);
        }}
      >
        {/* PUSH NOTIFICATION */}
        <div className="noti-content j-between">
          <div className="noti-content-left">
            <h4>Push notifications</h4>
            <p>Choose push notifications that will appear for users when they login to GoodLife</p>
          </div>
          <div className="noti-content-right">
            {Object.entries(initialValues.notification_in_app).map((item) => {
              const details = item[1];
              return (
                <div className="content j-between" key={`notification_in_app${item[0]}`}>
                  <div className="switch">
                    <Form.Item name={["notification_in_app", item[0], "enabled"]}>
                      <Switch onChange={onChange} loading={formLoading} />
                    </Form.Item>
                  </div>
                  <div className="text">
                    <div className="noti">{details.title}</div>
                    <p>{details.description}</p>
                  </div>
                  <div className="select">
                    {details.is_repeated && (
                      <Form.Item name={["notification_in_app", item[0], "frequency"]}>
                        <Select
                          options={options}
                          style={{ width: "150px" }}
                          onChange={handleChange}
                          loading={formLoading}
                        />
                      </Form.Item>
                    )}
                  </div>
                  <Form.Item name={["notification_in_app", item[0], "is_repeated"]} hidden>
                    <Switch />
                  </Form.Item>
                  <Form.Item name={["notification_in_app", item[0], "description"]} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={["notification_in_app", item[0], "title"]} hidden>
                    <Input />
                  </Form.Item>
                </div>
              );
            })}
          </div>
        </div>

        <div className="hr"></div>

        {/* EMAIL NOTIFICATION */}
        <div className="noti-content j-between">
          <div className="noti-content-left">
            <h4>Email notifications</h4>
            <p>Choose the email notifications that will be sent to the email inbox of users</p>
          </div>
          <div className="noti-content-right">
            {Object.entries(initialValues.notification_email).map((item) => {
              const details = item[1];
              return (
                <div className="content j-between" key={`notification_email${item[0]}`}>
                  <div className="switch">
                    <Form.Item name={["notification_email", item[0], "enabled"]}>
                      <Switch onChange={onChange} />
                    </Form.Item>
                  </div>
                  <div className="text">
                    <div className="noti">{details.title}</div>
                    <p>{details.description}</p>
                  </div>
                  <div className="select">
                    {details.is_repeated && (
                      <Form.Item name={["notification_email", item[0], "frequency"]}>
                        <Select options={options} style={{ width: "150px" }} onChange={handleChange} />
                      </Form.Item>
                    )}
                  </div>
                  <Form.Item name={["notification_email", item[0], "is_repeated"]} hidden>
                    <Switch />
                  </Form.Item>
                  <Form.Item name={["notification_email", item[0], "description"]} hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name={["notification_email", item[0], "title"]} hidden>
                    <Input />
                  </Form.Item>
                </div>
              );
            })}
          </div>
        </div>
      </Form>
    </div>
  );
};
