import { DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import { useCompany } from "context/CompanyContext";
import React from "react";
export const TeamList: React.FC = () => {
  const { id } = useCompany();
  const { tableProps } = useTable({
    resource: "team-company",
    initialSorter: [
      {
        field: "order",
        order: "asc",
      },
    ],
    permanentFilter: [
      {
        field: "company_id",
        operator: "eq",
        value: id,
      },
    ],
    metaData: {
      join: {
        field: "company",
      },
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Company",
      dataIndex: ["company", "name"],
      key: "company",
      sorter: true,
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      sorter: true,
    },

    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton
              shape="circle"
              hideText
              recordItemId={record.id}
            />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="team-company" />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h1 style={{fontSize: 35, marginBottom:30, color:"rgb(4, 6, 10, 0.8)"}}>Metadata</h1>
      <List title="Teams" canCreate>
        <Table {...tableProps} rowKey="id" columns={columns} />
      </List>
      </div>
  );
};
