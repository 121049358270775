import React, {useState} from 'react';
import { Tabs } from 'antd';
import { useMe } from "context/MeContext";
import UsersEngagement from "./Tabs/users-engagement"
import CompanyWellness from './Tabs/company-wellness';
import ChallengeParticipation from './Tabs/challenge-participation';
import "./adminDashboard.scoped.scss";
import { css } from '@emotion/react';
import { Segmented } from 'antd';
import ActivityEngagement from './Tabs/activity-engagement';

const { TabPane } = Tabs;

export const AdminDashboard: React.FC = () => {
  const { company, isSuperAdmin } = useMe();
  const [selectedTab, setSelectedTab] = useState('Users Engagement');
  return (
    <div css={css`
      .ant-segmented-item-label {
        padding: 5px 20px; 
        font-size: 15px;
      }
      .ant-segmented {
        padding: 7px;
      }
    `}>
      <h1 style={{fontSize: 35, marginBottom:30, color:"rgb(4, 6, 10, 0.8)", fontWeight: 500}}>Dashboard</h1>
      {/* <Tabs type="card" defaultActiveKey="1" size='small'>   
        <TabPane tab="Users Engagement" key="1" >
          <UsersEngagement />
        </TabPane>
        <TabPane tab="Company Wellness" key="2">
          <CompanyWellness />
        </TabPane>
        <TabPane tab="Challenge Participation" key="3">
          <ChallengeParticipation />
        </TabPane>
        <TabPane tab="Activity Engagement" key="4">
        </TabPane>
      </Tabs> */}

      <Segmented
        options={['Users Engagement', 'Company Wellness', 'Challenge Participation']}
        value={selectedTab}
        onChange={setSelectedTab}
      /> 
      <div style={{ marginTop: '16px' }}>
        {selectedTab === 'Users Engagement' && <UsersEngagement />}
        {selectedTab === 'Company Wellness' && <CompanyWellness />}
        {selectedTab === 'Challenge Participation' && <ChallengeParticipation />}
        {/* {selectedTab === 'Activity Engagement' && <ActivityEngagement />} */}
      </div>
    </div>

  );
};
