import React from 'react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import routerProvider from "@pankod/refine-react-router-v6";
import { useCompany } from "context/CompanyContext";

const { Link } = routerProvider;

const FAQ: React.FC = () => {
  const { path: company_path } = useCompany();
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'What is Goodlife?',
      children: 'GoodLife Wellness Solutions (GoodLife) is an innovative wellness platform that aims to transform individual health and well-being practices',
    },
    {
      key: '2',
      label: 'I forgot my password. How can I reset it?',
      children: 'If you’ve forgotten your password, click on the “Forgot Password” link on the login page. Follow the instructions sent to your registered email to reset your password',
    },
    {
      key: '3',
      label: 'How can I update my account information?',
      children: (<>To update your account information, log in to your account and navigate to the 
      <Link to={`/myaccount`}style={{color:"#333", fontWeight:500}}> My Profile</Link>. From there, you can modify your personal details</>),
    },
    {
      key: '4',
      label: 'What is your Privacy Policy?', 
      children: (<>For detailed information, please refer to our 
          <Link to={"/privacy"} style={{color:"#333", fontWeight:500}}> Privacy Policy</Link> page</>),
    },
    {
      key: '5',
      label: 'How can I contact customer support?',
      children: (<>For assistance, you can reach our customer support team through support@monumenthealth.net, (970) 683-5630 , or by using the contact form on our 
          <Link to={`/${company_path}/contact`} style={{color:"#333", fontWeight:500}}> Contact Us</Link> page</>),
    },
    {
      key: '6',
      label: 'Is my personal information secure on Goodlife?',
      children: 'Yes, we take the security of your personal information seriously. Our website employs encryption to safeguard your data',
    },
    // {
    //   key: '7',
    //   label: 'How can I unsubscribe from newsletters and promotional emails?',
    //   children: 'To unsubscribe from newsletters and promotional emails, click the “Unsubscribe” link at the bottom of the email',
    // },
  ];
  return (
    <Collapse items={items} bordered={false} style={{background:"white"}}/>
  )
} ;

export default FAQ;