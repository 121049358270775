export enum DataType {
  Numeric = "Numeric",
  "Yes/No" = "Yes/No",
  Dropdown = "Dropdown",
  Date = "Date",
  FreeText = "FreeText",
}

export interface NumericResult {
  from: number;
  to: number;
  point: number;
}
export interface YesNoResult {
  yes: number;
  no: number;
}
export interface DropdownResult {
  answer: number;
  point: number;
}

export interface DateResult {
  tick: number;
  no_tick: number;
}

export interface WeekStatsResult {
  Numeric: NumericResult[];
  yesno: YesNoResult[];
  dropdown: DropdownResult[];
  date: DateResult[];
}

export interface WeekStats {
  title: string;
  description: string;
  data_type: DataType;
  results: WeekStatsResult;
  order: number;
  hidden_required_proof?: boolean;
}
