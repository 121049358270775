import React from 'react';
import { Tabs } from 'antd';
import { useMe } from "context/MeContext";
import "../adminDashboard.scoped.scss";
import MedianWellnessScoreByDemographic from '../Charts/median-wellness-score-by-demographic';
import ScoringByDimension from '../Charts/scoring-by-dimension';
import MedianWellNessScore from '../Charts/median-wellness-score';
import { css } from '@emotion/react';
import GenderChart from '../Charts/gender-chart';
import Image from "../../../../assets/img/oct10.jpg"
import Image1 from "../../../../assets/img/Save.png";
import ChallengeParticipated from '../Charts/challenge-participated';
import AllPartipipants from '../Charts/all-participants';
import ChallengeCompleted from '../Charts/challenge-completed';
import ExampleLine from '../Charts/example-line';
import { Select} from 'antd';
const { TabPane } = Tabs;

export const ChallengeParticipation: React.FC = () => {
  const { company, isSuperAdmin } = useMe();
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  return (
    <div className='tab tab1' css={css`
        .recharts-wrapper {
          margin: auto !important;
          align-items: center;
        }
        .box {
          width: 23%
        }
        .box2 {
          width: 29%;
        }
        .box0 {
          width: 69%;
          padding: 20px;
        }
            
    `}>
      <div className="middle">
        <div className="single-box j-between challenge-title-group">
          <div className="left-challenge">
            <div className='challenge-title no-padding'>Degital Detox</div>
            <p>Date started: 10/01/2024 | Challenge duration: 4 weeks</p>
          </div>
          <div className="right-challenge">
            <Select
              placeholder="Choose a Challenge"
              style={{ width: "auto" }}
              onChange={handleChange}
              options={[
                { value: 'digitalDetox', label: 'Digital Detox' },
                { value: 'walktober', label: 'Walktober' },
              ]}
            />
          </div>
        </div>
      </div>
      <br/>
      <div className="top j-between">
        <div className="single-box box0">
          <div className="toptop j-between">
            <div className='toptop-chart'>
              <h2 className='no-padding-all'>Joined</h2>
              <h1 className='t-center joined'>233</h1>
            </div>
            {/* <div><ExampleLine/></div> */}
          </div>
          <div className="topbot j-between" style={{marginBottom:"-100px", marginTop:"20px"}}>
            <div className='topbot-chart' >
              <h2 className='no-padding-all'>Participated</h2>
              <ChallengeParticipated />
            </div>
            {/* <div className="vt"></div> */}
            <div className='topbot-chart'>
              <h2 className='no-padding-all'>Completed</h2>
              <ChallengeCompleted />
            </div>
          </div>
        </div>
        <div className="single-box box2 most-activity-group" >
          <div className="box2-content">
            <h2 className='no-padding'>Most Completed Activity</h2>
            <div className="t-center most-activity">Read a book at night instead of “doom scrolling”</div>
          </div>
        </div>
      </div>
      <br />
      <div className="bottom">
        <div className="single-box">
          <AllPartipipants />
        </div>
      </div>
    </div>
  );
};

export default ChallengeParticipation;
