import React from "react";
import { RefineLayoutHeaderProps } from "@pankod/refine-ui-types";
import { Layout } from "antd";
import { LinkedinFilled, FacebookFilled } from "@ant-design/icons";
import { IconPhoneCall, IconEnvelope, IconLocationOutline } from "components/Icons";
import "./footer.scoped.scss";
import logo from "assets/img/logologo.png";
import bgRes from "assets/img/res-footer.png";
import routerProvider from "@pankod/refine-react-router-v6";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { generateURL } from "utils/generateURL";
const { Link } = routerProvider;

export const Footer: React.FC<RefineLayoutHeaderProps> = () => {
  const { path: company_path, footer, brand } = useCompany();
  const banner = footer?.background_url
  return (
    <Layout.Footer
      style={{
        padding: "0",
        width: "100%",
      }}
    >
      <div className="footer" css={css`
        background: url("${generateURL(banner)} ") no-repeat top center/cover; 
        @media screen and (max-width: 577px) {
          width: 100%;
          overflow-x: hidden;
          background: url("${generateURL(footer?.responsiveFooter)}") no-repeat top
          center/cover !important;
          h5 {
            color: rgba(255, 255, 255, 0.65) !important;
            font-weight: 600;
          }
          .link {
            color: rgba(255, 255, 255, 0.9) !important;
          }
          .left-child img {
            background-color: white !important;

          }
        }
      `}>
        <div className="container j-between footer-container flex-wrap">
          <div className="footer-left">
            <div className="left-child">
              <img alt="logo" src={logo} />
              {/* <p className="company-slogan">Make A Choice To Improve Employee Wellbeing</p> */}
            </div>
          </div>

          <div className="footer-right j-between">
            <div className="site-column">
              <h5>SITEMAP</h5>
              <Link to={`${company_path}`} className="link" target="_self">
                Home
              </Link>
              <Link to={`${company_path}/challenge`} className="link">
                Challenges
              </Link>
              <Link to={`${company_path}/activity`} className="link">
                Activities
              </Link>
              <Link to={`${company_path}/hraresults`} className="link">
                HRA
              </Link>
              <Link to={`${company_path}/forums`} className="link">
                Chat
              </Link>
              <Link to={`${company_path}/resources`} className="link">
                Resources
              </Link>
            </div>
            <div className="site-column site-column-right">
              <h5>COMPANY</h5>
              <a href="https://monumenthealth.net/" className="link" target="_blank" rel="noreferrer">
                About Us
              </a>
              <Link to={`${company_path}/contact`} className="link">
                Contact Us
              </Link>
            </div>
          </div>

          <div className="footer-middle">
            <h5 className="contact-title">CONTACT US</h5>
            <div className="link link-contact">
              <a href="tel:9706835690">
                <span className="icon inline-block">
                  <IconPhoneCall />{" "}
                </span>
                (970) 683-5630
              </a>
            </div>
            <div className="link link-contact">
              <a href="mailto:support@monumenthealth.net">
                <span className="icon inline-block">
                  <IconEnvelope />
                </span>{" "}
                support@monumenthealth.net
              </a>
            </div>
            <div className="link link-contact">
              <a href="https://monumenthealth.net/" target="_blank" rel="noreferrer">
                <span className="icon inline-block icon-location">
                  <IconLocationOutline />{" "}
                </span>
                <div className="inline-block link-contact">
                  <div>461 Main Street, Suite 200</div>
                  <div>Grand Junction, CO 81501</div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom" css={css`
         background-color: ${brand?.color_primary};
        `}>
          <div className="line" />
          <div className="container j-between bottom-container">
            <div className="bottom-left">© Copyright2023 Monument Health. All rights reserved.</div>
            <div className="bottom-right j-between">
              <div className="right-left j-between">
                <a
                  href="https://www.facebook.com/MonumentHealthGJ"
                  className="social-media"
                  target="_blank"
                  rel="noreferrer"
                > 
                  <FacebookFilled />
                </a>
                <a
                  href="https://www.linkedin.com/company/monument-health/"
                  className="social-media"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedinFilled />
                </a>
              </div>
              <div className="j-between right-right">
                <span>
                  {" "}
                  <Link to={"privacy"} className="name">
                    End User Agreement
                  </Link>
                </span>
                <span className="ver-line" />
                <span>
                  {" "}
                  <Link to={"terms"} className="name">
                    Terms of Service
                  </Link>
                </span>
                <span className="ver-line" />
                <span>
                  {" "}
                  <Link to={"privacy"} className="name">
                    Privacy Policy
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout.Footer>
  ); 
};
