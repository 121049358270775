import React from 'react';
import { Tabs } from 'antd';
import { useMe } from "context/MeContext";
import "../adminDashboard.scoped.scss";
import { PieChart, Pie, Legend } from "recharts";
import GenderChart from "../Charts/gender-chart";
import AgeChart from "../Charts/age-chart";
import TotalUsers from "../Charts/total-users";
import TotalUsersPastSixmo from '../Charts/total-users-past-sixmo';
import UserTrophyAchievement from '../Charts/user-trophy-achievement';
import { css } from '@emotion/react';
const { TabPane } = Tabs;

export const UsersEngagement: React.FC = () => {
  const { company, isSuperAdmin } = useMe();
  return (
    <div className='tab tab1' css={css`
        .recharts-wrapper {
          margin: auto !important;
          align-items: center;
        }
        .top {
          width: 100%;
        }
        .box {
          min-width: 26%;
        }
        .box2 {
          min-width: 36%;
        }
      `}>
      {/* <h1>USERS ENGAGEMENT</h1> */}
      <div className="top j-between">
        <div className='single-box box'>
         <h2 className='no-padding'>Total Users</h2>
          <TotalUsers />
        </div>
        <div className='single-box box'>
          <h2 className='no-padding'>Gender</h2>
          <GenderChart />
        </div>
        <div className='single-box box2'>
          <h2 className='no-padding'>Age</h2>
          <AgeChart />
        </div>
      </div>
      <br/>
      <div className="bottom j-between">
          <div className="single-box total-user-group" >
            <h2>Total Users in Past 6 Months</h2>
            <TotalUsersPastSixmo />
          </div>
          <div className="single-box total-user-group">
            <h2>Sign-Ups in Past 6 Months</h2>
            <TotalUsersPastSixmo />
          </div>
      </div>
      <br/>
      <div className="single-box">
        <h2>User Trophy Achievement</h2>
        <UserTrophyAchievement />
      </div>

    </div>
  );
};

export default UsersEngagement;
