import React, {useState,useEffect} from "react";
import { css } from "@emotion/react";
import { Button, Form, Input, SaveButton, useSelect, } from "@pankod/refine-antd";
import { Select } from "antd";
import { useCompany } from "context/CompanyContext";
import { useMe } from "context/MeContext";
import { useOne, useUpdate } from "@pankod/refine-core";
import { IUserUpdate } from "types/user";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
const options=[
  {value: 'weekly', label: 'Weekly'},
  {value: 'monthly', label: 'Monthly'},
  {value: 'quarterly', label: 'Quaterly'},
]
const EmployeeDetails: React.FC = () => {
  const { hra_summary } = useCompany();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { me: user, company } = useMe();
  const [employeeDetails, setEmployeeDetails] = useState(true);

  const { data, refetch } = useOne({
    resource: "users",
    id: user?.id!,
    queryOptions: {
      enabled: Boolean(user?.id),
    },
  });

  const { mutateAsync: updateUser, isLoading } = useUpdate();
  const [formInfo] = Form.useForm();
  const userData = data?.data;
  // async function onSave(data: IUserUpdate) {
  //   await updateUser({
  //     resource: "users",
  //     id: user?.id!,
  //     values: data,
  //   });
  //   return (
  //     <Popconfirm
  //       title="Delete the task"
  //       description="Are you sure to delete this task?"
  //       okText="Yes"
  //       cancelText="No"
  //     ></Popconfirm>
  //   );
  // }

  //const icon = user?.isChangedIcon ? config.ASSET_URL + user?.icon : user?.icon;

  //const iconData = Form.useWatch("icon", form);

  // const onChange = async (e: CheckboxChangeEvent) => {
  //   await updateUser({
  //     resource: "users",
  //     id: user?.id!,
  //     values: {
  //       hideMeDashboard: e.target.checked,
  //     },
  //   });
  // };
  // const onChangeChart = async (e: CheckboxChangeEvent) => {
  //   await updateUser({
  //     resource: "users",
  //     id: user?.id!,
  //     values: {
  //       user_chart_status: e.target.checked,
  //     },
  //   });
  // };

  const { selectProps: selectPropsLocation } = useSelect({
    resource: "location",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const { selectProps: selectPropsTeam } = useSelect({
    resource: "team-company",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const { selectProps: selectPropsJobTitle } = useSelect({
    resource: "job-title",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const { selectProps: selectPropsEmployeeType } = useSelect({
    resource: "employee-type",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "company_id",
        operator: "eq",
        value: company?.id,
      },
    ],
    queryOptions: {
      enabled: Boolean(company?.id),
    },
    pagination: {
      pageSize: 100,
    },
  });

  const professional_info = [
    {
      title: "Location",
      props: selectPropsLocation,
      placeholder: "Please choose your location",
      name: ["location", "id"],
      defaultField: "location_id",
    },
    {
      title: "Team",
      props: selectPropsTeam,
      placeholder: "Please choose your team",
      name: ["team_company", "id"],
      defaultField: "team_company_id",
    },
    {
      title: "Employee type",
      props: selectPropsEmployeeType,
      placeholder: "Please choose your employee type",
      name: ["employee_type", "id"],
      defaultField: "employee_type_id",
    },
    {
      title: "Job title",
      props: selectPropsJobTitle,
      placeholder: "Please choose your job title",
      name: ["job_title", "id"],
      defaultField: "job_title_id",
    },
  ];

  useEffect(() => {
    if (
      selectPropsEmployeeType?.options?.length === 0 &&
      selectPropsJobTitle?.options?.length === 0 &&
      selectPropsLocation?.options?.length === 0 &&
      selectPropsTeam?.options?.length === 0
    ) {
      setEmployeeDetails(true);
    } else {
      setEmployeeDetails(false);
    }
  }, [
    selectPropsEmployeeType?.options?.length,
    selectPropsJobTitle?.options?.length,
    selectPropsLocation?.options?.length,
    selectPropsTeam?.options?.length,
  ]);
  return (
    <div css={css`
      background-color: white;
      p {
        color: rgba(124, 124, 124, 0.7);
      }
      .noti-content-right {
        width: 100%;
      }
      .text {
        text-align: left;
        width: 70%;
        .noti {
          font-weight: 500;
          text-align: left;
          color: #333;
        }
        p {
          text-align: left;
        }
      }
      .select {
        width: 70%;
        .select-select {
        width: 100%;
        }
      }
      .ant-switch.ant-switch-checked {
        background-color: ${hra_summary?.main_hra_color} !important;
      }
      .hr {
        border-top: 1px solid rgba(124, 124, 124, 0.2);
        margin: 20px 0;
        width: 70%;
      }
      .hide {
        display: none;
      }
    `}>
      <h1>Employee Details</h1>
      {employeeDetails ? <p>There are no updates needed for your Employee Detail at the moment</p> :  <p>View and update your employment information</p>}
      <div className="hr"></div>
      {/* Details */}
      <div className={`${employeeDetails ? "hide" : "noti-content" }`}>
        <div className="noti-content-right">
          {userData && (
          <div className={`${employeeDetails ? "none" : "right"}`}>
            <Form
              form={formInfo}
              onFinish={async (e) => {
                await updateUser({
                  resource: "users",
                  id: user?.id!,
                  values: e,
                });
                refetch();
              }}
              initialValues={{
                location: {
                  id: userData.location_id || "",
                },
                team_company: {
                  id: userData.team_company_id || "",
                },
                job_title: {
                  id: userData.job_title_id || "",
                },
                employee_type: {
                  id: userData.employee_type_id || "",
                },
              }}
            >
              {professional_info.map((item) => {
                return item?.props?.options?.length === 0 ? null : (
                  <Form.Item key={item.title} name={item.name}>
                    <div>
                      {/* <div className="hr"></div> */}
                      <div className="text j-between">
                        <p className="noti">{item.title}</p>
                        <p className="select">
                          <Select
                            showSearch
                            filterSort={(optionA: any, optionB: any) =>
                              (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                            }
                            {...item.props}
                            className="select-select"
                            defaultValue={userData[item.defaultField]}
                            placeholder={item.placeholder}
                            onChange={(e: any) => {
                              formInfo.setFieldValue(item.name, e);
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </Form.Item>
                );
              })}
              <div style={{width:"70%"}}>
                <Button
                  onClick={formInfo.submit}
                  css={css`
                    display: flex !important;
                    float: right !important;
                    background-color: ${hra_summary?.main_hra_color} !important;
                    color: white !important;
                    border-radius: 10px !important;
                    padding: 5px 20px !important;
                    height: 100%;
                    &:hover {
                      color: ${hra_summary?.main_hra_color} !important;
                      background-color: ${hra_summary?.title_color} !important;
                    }
                  `}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        )}


        </div>
      </div>
    </div>
  )
}
export default EmployeeDetails;
