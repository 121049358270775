export enum FrequencyTime {
  Weekly = "Weekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
}

export interface NotificationInApp {
  [type: string]: {
    is_repeated?: boolean;
    frequency?: FrequencyTime;
    enabled: boolean;
    description: string;
    title: string;
  };
}
