import React from 'react';
import { Tabs } from 'antd';
import { useMe } from "context/MeContext";
import "../adminDashboard.scoped.scss";
import MedianWellnessScoreByDemographic from '../Charts/median-wellness-score-by-demographic';
import ScoringByDimension from '../Charts/scoring-by-dimension';
import MedianWellNessScore from '../Charts/median-wellness-score';
import { css } from '@emotion/react';
const { TabPane } = Tabs;

export const CompanyWellness: React.FC = () => {
  const { company, isSuperAdmin } = useMe();
  return (
    <div className='tab tab1' css={css`
        .recharts-wrapper {
          margin: auto !important;
          align-items: center;
        }
      `}>
      {/* <h1>COMPANY WELLNESS</h1> */}
      <div className="top j-between" style={{ width: '100%'}}>
        <div className="left">
          <div className='single-box'>
            <h2 className='no-padding'>Scoring by Dimension</h2>
            <ScoringByDimension />
          </div>
          <br/>
          <div className='single-box'>
            <h2 className='no-padding'>Median Wellness Score by Demographic</h2>
            <MedianWellnessScoreByDemographic />
          </div>
        </div>
        <div className="right">
          <div className='single-box right-box'>
            <div className='wellness-score-box'>
              <h2 className='no-padding t-center'>Median Wellness Score</h2>
              <h4 className='t-center score'>0.28</h4>
            </div>
            <MedianWellNessScore />
          </div>
        </div>

      </div>
    </div>
  );
};

export default CompanyWellness;
