import { QuestionCircleFilled } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Button, Form, FormInstance, Input, InputNumber, Tooltip } from "@pankod/refine-antd";
import { AnswerList } from "components/AnswerList";
import HRA, { UserAnswerList } from "pages/HRA";
import React from "react";
import { useCompany } from "context/CompanyContext";
import "./hra.scoped.scss";

interface GenerateQuestionListProps {
  questions?: any[];
  isChild?: boolean;
  setUserAnswerList: React.Dispatch<React.SetStateAction<UserAnswerList[]>>;
  form: FormInstance<any>;
}
interface FreeTextInputProps {
  item: any;
  onFreeTextChange: (value: string, id: number, isHeight?: boolean) => void;
}
export const FreeTextInput: React.FC<FreeTextInputProps> = ({ item, onFreeTextChange }) => {
  const { hra_summary } = useCompany();
  if (item.description.includes("height")) {
    return (
      <div className="answer flex space-x-2">
        <span className="text mb-4">
          <Form.Item
            className="mb-0"
            name={`question-ft`}
            rules={[
              {
                required: true,
                message: "This question is required!",
                type: "integer",
              },
            ]}
          >
            <InputNumber
              min={1}
              className="w-full"
              onChange={(e) => {
                onFreeTextChange(e as any, item.id, true);
              }}
              css={css`
                &:hover {
                  border: 1px solid ${hra_summary?.main_hra_color} !important;
                }
              `}
            />
          </Form.Item>
          <span className="text-[#ccc]">feet</span>
        </span>
        <span className="text">
          <Form.Item
            className="mb-0"
            name={`question-inch`}
            rules={[
              {
                required: false,
                // message: 'This question is required!',
                type: "integer",
              },
            ]}
          >
            <InputNumber
              min={0}
              max={12}
              className="w-full"
              onChange={(e) => {
                onFreeTextChange(e as any, item.id, true);
              }}
              css={css`
                &:hover {
                  border: 1px solid ${hra_summary?.main_hra_color} !important;
                }
              `}
            />
          </Form.Item>
          <span className="text-[#ccc]">inches</span>
        </span>
      </div>
    );
  }
  return (
    <div className="answer">
      <span className="text">
        <Form.Item
          name={`question${item.id}`}
          rules={[
            {
              required: true,
              message: "This question is required!",
            },
          ]}
        >
          <Input
            className="w-[100%] mr-2 input-hra"
            onChange={(e) => {
              onFreeTextChange(e.target.value, item.id);
            }}
            css={css`
              &:hover {
                border: 1px solid ${hra_summary?.main_hra_color} !important;
              }
            `}
          />
        </Form.Item>
        {item.answer_detail.items[0]?.description}
      </span>
    </div>
  );
};

export const GenerateQuestionList: React.FC<GenerateQuestionListProps> = ({
  questions,
  isChild,
  setUserAnswerList,
  form,
}) => {
  const onFreeTextChange = (value: string, id: number) => {
    setUserAnswerList((curr) => {
      return curr.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            text: value,
          };
        }
        return item;
      });
    });
  };
  const { hra_summary } = useCompany();
  return (
    <div
      css={css`
        a {
          &:hover {
            color: ${hra_summary?.main_hra_color};
          }
        }
      `}
    >
      {questions?.map((item) => {
        const showFreeTextArea = item.answer_detail?.items?.length === 1;
        const type = item.type;
        const childQuestions = item.childQuestions?.sort((a: any, b: any) => a.name - b.name);

        return (
          <div
            key={item.id}
            className="question-box pb-3"
            css={css`
              border-bottom: ${isChild ? "0px" : "1px"} solid black;
            `}
          >
            <div className="question">
              {isChild ? <h5>{item.description}</h5> : <h3>{item.description}</h3>}
              {!isChild && (
                <Tooltip
                  title={
                    <>
                      <div className="tooltip">{item.question_source.description}</div>{" "}
                      <a
                        href={item.question_source.additional}
                        target="_blank"
                        rel="noreferrer"
                        className="learnmore"
                        css={css`
                          color: ${hra_summary?.main_hra_color} !important;
                          background-color: ${hra_summary?.background_color} !important;
                          &:hover {
                            background-color: ${hra_summary?.main_hra_color} !important;
                          }
                        `}
                      >
                        {" "}
                        Learn more.
                      </a>
                    </>
                  }
                >
                  <Button className="why-ask-group">
                    <QuestionCircleFilled
                      className="question-mark-icon"
                      css={css`
                        background-color: ${hra_summary?.main_hra_color} !important;
                        color: ${hra_summary?.background_color} !important;
                      `}
                    />
                    <div
                      className="why-ask"
                      css={css`
                        color: ${hra_summary?.main_hra_color} !important;
                      `}
                    >
                      Why are we asking this?
                    </div>
                  </Button>
                </Tooltip>
              )}
            </div>

            <div className="answer-group">
              <div className="flex flex-col w-full">
                {showFreeTextArea && <FreeTextInput item={item} onFreeTextChange={onFreeTextChange} />}
                {!showFreeTextArea && (
                  <AnswerList
                    form={form}
                    id={item.id}
                    setUserAnswerList={setUserAnswerList}
                    type={type}
                    data={item.answer_detail.items}
                  />
                )}
              </div>
            </div>
            {childQuestions?.length > 0 && (
              <GenerateQuestionList
                form={form}
                setUserAnswerList={setUserAnswerList}
                questions={childQuestions}
                isChild
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
